//AntDesign
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import { displayTitleIndex, getTitlesConfig } from '../../utils/titles';
import { isATitleBox } from '../../utils/boxes';
//CSS
import './summarySelector.css';
import _ from 'lodash';
import { MenuOutlined } from '@ant-design/icons';
import NavbarSelector from '../Layout/NavbarSelector';

export const getDisplayableTitles = (boxes, summaryConfiguration, configuration) => {
  return boxes
    .filter((box) => isATitleBox(box.type) && summaryConfiguration[box.type].visibility)
    .map((title) => {
      return {
        text: title.content.editorState.getCurrentContent().getPlainText(),
        id: title.id,
        pageNumber: title.pageNumber,
        type: title.type,
        indexTitle: displayTitleIndex(
          title.content.indexTitle,
          configuration.separatorTitle,
          getTitlesConfig(configuration),
          configuration.hideTitleIndex
        ),
      }
    })
}

// To handle legacy configs with integer and not ids
export const getIdsFromConfig = (startConfigValue, endConfigValue, displayableTitles, allTitles, startFrom, duppedBreakIdx) => {
  if (displayableTitles.length === 0 || allTitles.length === 0) {
    return {
      idStart: 0,
      idEnd: 0
    }
  }
  const getId = (configValue, defaultValue, start = true) => {
    // we use to have number as index for values
    if (configValue && _.isString(configValue)) {
      const title = displayableTitles.find((t) => t.id === configValue);
      if (title) { return configValue }
      // if config has changed title with lower level can be hidden
      // but inherited from previous config
      const previousTitleId = allTitles.slice(0, allTitles.findIndex((t) => t.id === configValue))
        .reverse()
        .find((t) => displayableTitles.map((t) => t.id).includes(t.id))
        .id;
      if (start) {
        // previous title that can be displayed if start
        return previousTitleId;
      } else {
        // next title that can be displayed if end
        const nextTitle = allTitles.slice(allTitles.findIndex((t) => t.id === configValue))
          .find((t) => displayableTitles.includes(t.id));
        return nextTitle ? nextTitle.id : previousTitleId
      }
    }
    if (start && startFrom) {
      return displayableTitles[startFrom].id
    }
    if (!start && duppedBreakIdx) {
      return displayableTitles[~~startFrom + duppedBreakIdx - 1].id
    }
    return defaultValue
  }

  return {
    idStart: getId(startConfigValue, displayableTitles[0].id),
    idEnd: getId(endConfigValue, displayableTitles[displayableTitles.length - 1].id, false)
  }
}

const selector = (id) => (state) => {
  const {
    boxes,
    updateBox,
    configuration,
    selectedBoxId,
    summaryConfiguration,
  } = state;
  const allTitles = boxes.filter((box) => isATitleBox(box.type));
  const displayableTitles = getDisplayableTitles(boxes, summaryConfiguration, configuration)
  const { lineSummaryStart, lineSummaryEnd } = boxes.find((b) => b.id === selectedBoxId).content.configuration
  const { idStart, idEnd } = getIdsFromConfig(lineSummaryStart, lineSummaryEnd, displayableTitles, allTitles)
  return {
    titles: displayableTitles,
    idStart,
    idEnd,
    updateBox: updateBox,
    separator: configuration.separatorTitle,
    summaryConfiguration,
  };
};

export const SummarySelector = ({ selectedBox }) => {
  var { updateBox, idStart, idEnd, titles } = useStore(
    selector(selectedBox.id)
  );

  const { t } = useTranslation();
  if (titles.length === 0) { return null };

  const selectStartTitle = (id) => {
    const endIndex = titles.findIndex((title) => title.id === idEnd);
    const thisIndex = titles.findIndex((title) => title.id === id);
    if (thisIndex > endIndex) return null;
    updateBox(selectedBox?.id, (selectedBox) => {
      selectedBox.content.configuration.lineSummaryStart = id;
    });
  }

  const selectEndTitle = (id) => {
    const startIndex = titles.findIndex((title) => title.id === idStart);
    const thisIndex = titles.findIndex((title) => title.id === id);
    if (startIndex > thisIndex) return null;
    updateBox(selectedBox?.id, (selectedBox) => {
      selectedBox.content.configuration.lineSummaryEnd = id;
    });
  }

  const options = titles.map(t => ({
    label: `${t.indexTitle} ${t.text}`,
    value: t.id
  }))

  return (
    <>
      <NavbarSelector
        key="summaryStartLineIndex"
        tooltipContent={t(`Délimiteur de début d'affichage du sommaire`)}
        disabled={selectedBox?.type !== 'summary'}
        suffixIcon={<MenuOutlined />}
        onSelect={selectStartTitle}
        selectedValue={idStart}
        options={options}
      />
      <NavbarSelector
        key="summaryEndLineIndex"
        tooltipContent={t(`Délimiteur de fin d'affichage du sommaire`)}
        disabled={selectedBox?.type !== 'summary'}
        suffixIcon={<MenuOutlined />}
        onSelect={selectEndTitle}
        selectedValue={idEnd}
        options={options}
      />
    </>
  );
};

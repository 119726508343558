import React, { useState } from 'react';

// ANT Design
import { Select, Tooltip, ConfigProvider } from 'antd';
import { navbarElementStyle } from '../../utils/styles';
import { useKeyboard } from '../../hooks';
const { Option } = Select;

const NavbarSelector = ({
  tooltipContent,
  width,
  disabled,
  suffixIcon,
  onSelect,
  selectedValue,
  options,
  filterOption,
  customStyle = {},
  searchable = true,
  arrow = true,
  placeholder,
  allowClear,
  className,
  dropdownStyle
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const setIsUsingKeyboard = useKeyboard(false);

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextDisabled: 'gray',
            colorBgContainerDisabled: 'white'
          },
        },
      }}
    >
      <Tooltip
        open={tooltipVisible}
        placement='bottom'
        mouseLeaveDelay='0'
        title={tooltipContent}
      >
        <Select
          className={`navbar-selector ${className ?? ''}`}
          showSearch={searchable}
          style={{
            ...navbarElementStyle,
            width: width || '120px',
            ...customStyle,
          }}
          value={selectedValue}
          disabled={disabled}
          onFocus={() => setIsUsingKeyboard(true)}
          onBlur={() => setIsUsingKeyboard(false)}
          onMouseEnter={() => !isOpen && setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
          onDropdownVisibleChange={(open) => {
            setIsOpen(open);
            if (open) {
              setTooltipVisible(false)
            }
          }}
          suffixIcon={suffixIcon}
          showArrow={arrow}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={onSelect}
          filterOption={filterOption ?? ((input, option) => {
            return (option?.label.toString() ?? '').toLowerCase().includes(input.toString().toLowerCase())
          })}
          onInputKeyDown={(e) => { e.stopPropagation() }}
          optionLabelProp="label"
          allowClear={allowClear}
          dropdownStyle={dropdownStyle}
        >
          {options.map(({ value, label, style }) => {
            return (
              <Option key={value} value={value} label={label} style={style}>
                {label}
              </Option>
            )
          })}
        </Select>
      </Tooltip>
    </ConfigProvider>
  );
};

export default React.memo(NavbarSelector);

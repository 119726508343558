import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import './../common/Utils.css';
import { useTranslation } from 'react-i18next';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';

const ButtonTableNavbar = ({ itemKey, type, onClick }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <NavbarButton
        tooltipContent={t('left-alignment')}
        onClick={(e) => onClick({ action: 'alignment', value: 'start' }, itemKey)}
        disabled={type !== 'header'}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-left', style: 'light' })} style={iconButtonStyle()} />}
        className="pop-menu-btn"
      />
      <NavbarButton
        tooltipContent={t('center-alignment')}
        onClick={(e) => onClick({ action: 'alignment', value: 'center' }, itemKey)}
        disabled={type !== 'header'}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-center', style: 'light' })} style={iconButtonStyle()} />}
        className="pop-menu-btn"

      />
      <NavbarButton
        tooltipContent={t('right-alignment')}
        onClick={(e) => onClick({ action: 'alignment', value: 'end' }, itemKey)}
        disabled={type !== 'header'}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-right', style: 'light' })} style={iconButtonStyle()} />}
        className="pop-menu-btn"

      />
    </div>
  );
};

export default ButtonTableNavbar;

import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import { NavbarListButton } from '../Layout/NavbarListButton';
import { filterSameColor, iconButtonStyle } from '../../utils/styles';

const selector = ({ configuration }) => ({
  configuration,
});

const ColorControl = (props) => {
  const { configuration } = useStore(selector);
  var currentStyle = props?.editorState?.getCurrentInlineStyle(props.editorState.getSelection());
  let currentColor = props.colors.find((color) => currentStyle?.has(color.label)) ?? props.defaultColor ?? configuration?.baseFontColor;
  const { t } = useTranslation();

  return (
    <NavbarListButton
      title={t('font-color')}
      disabled={false}
      icon={
        <p style={{ ...iconButtonStyle(), fontFamily: 'monospace' }}>
          A
        </p>
      }
      onListElementClick={props.onToggle}
      listElements={filterSameColor(props.colors.filter((c) => c.label !== 'transparent'))}
      selectedElement={currentColor}
    />
  );
};

export default ColorControl;

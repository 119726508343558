import produce from 'immer';
import {
  mutateDeleteSection,
  mutateHandleLinkedSectionRecursive,
  mutateInjectSection,
} from '../utils/sections';
import { fillEmptyAdminTablesBoxes } from '../utils/table';
import { ItemTypes } from '../constants/constants';

export const sectionsActions = (set, get) => ({
  injectSection: async ({ id, boxId, linked_section = false }) => {
    const section = await get().getTemplate(id);
    if (!section) return;
    const currentDocument = get().currentDocument;
    const sectionContent =
      currentDocument.type === 'template'
        ? section.content
        : {
            ...section.content,
            boxes: await fillEmptyAdminTablesBoxes({
              boxes: section.content.boxes,
              proposable_id: currentDocument.id,
              type: currentDocument.type,
            }),
          };
    set(
      produce((draft) => {
        mutateInjectSection({
          draft,
          sectionContent,
          boxId,
          sectionId: section.id,
          linked_section,
        });
      }),
      false,
      `Inject section ${id}`
    );
  },
  handleSectionAction: async ({
    boxId,
    optionId,
    sectionId,
    action,
    areItemsChangedByTriggers,
    linked_section = false,
    fromSectionId = sectionId,
    dateTimestamp,
    fromBoxId,
    isSecondInjection = false,
    from_linked_section,
    sectionsIds,
    wasDocumentLoading,
  }) => {
    if (action === 'remove') {
      set(
        produce((draft) => {
          const box = draft.boxes.find((b) => b.id === boxId);
          if (box?.type === ItemTypes.SECTION_VARIABLE && optionId)
            box.answer = optionId;
          mutateDeleteSection({ draft, boxId, optionId });
        })
      );
      return;
    }
    const section = await get().getTemplate(sectionId);
    if (section?.error) return;
    const currentDocument = get().currentDocument;
    const sectionContent =
      currentDocument.type === 'template'
        ? section.content
        : {
            ...section.content,
            boxes: await fillEmptyAdminTablesBoxes({
              boxes: section.content.boxes,
              proposable_id: currentDocument.id,
              type: currentDocument.type,
            }),
          };
    set(
      produce((draft) => {
        const box = draft.boxes.find((b) => b.id === boxId);
        if (action === 'inject') {
          if (linked_section) {
            const hasInfiniteLoop = mutateHandleLinkedSectionRecursive({
              sectionsIds,
              box,
              draft,
              fromBoxId,
              boxId,
              sectionContent,
              isSecondInjection,
            });
            if (hasInfiniteLoop) return;
          }
          if (box.type === ItemTypes.SECTION_VARIABLE && optionId)
            box.answer = optionId;
          mutateInjectSection({
            draft,
            sectionContent,
            boxId,
            sectionId: section.id,
            areItemsChangedByTriggers,
            linked_section,
            fromSectionId,
            dateTimestamp,
            fromBoxId,
            from_linked_section,
            sectionsIds,
            wasDocumentLoading,
          });
        } else if (action === 'reinitialize') {
          mutateDeleteSection({ draft, boxId, optionId });
          mutateInjectSection({
            draft,
            sectionContent,
            boxId,
            sectionId: sectionId,
            areItemsChangedByTriggers,
            linked_section,
            fromSectionId,
            dateTimestamp,
            fromBoxId,
            from_linked_section,
            sectionsIds,
            wasDocumentLoading,
          });
        }
      }),
      false,
      `handleSectionAction from section ${boxId}`
    );
  },
});

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
// import Select from 'rc-select';
import { useTranslation } from 'react-i18next';
import { filterSameColor, iconButtonStyle } from '../../utils/styles'
import { useStore } from '../../store/store';
import { onChangeColor } from '../RichEditor/utils';
import { NavbarListButton } from './NavbarListButton';
import { useCallback } from 'react';
import { ItemTypes } from '../../constants/constants';
import { isASelectionBox, isATextBox, isAVariableBox } from '../../utils/boxes';
import { Separator } from './Navbar';
import _ from 'lodash';

const selector = ({ updateBox, configuration, selectedBoxId, boxes, masks, containers, currentProposal }) => {
  const box = [
    ...boxes,
    ...masks.map((m) => m.boxes).flat(),
  ].find((box) => selectedBoxId && box.id === selectedBoxId)
  const container = containers.find(container => container.columnsIds.includes(box?.columnId))

  return {
    updateBox,
    configuration,
    selectedBox: box,
    container: container,
    currentProposal,
    isSelectionBox: isASelectionBox(boxes.find((b) => b.id === selectedBoxId)?.type),

  }
};

export const BackgroundColorPicker = () => {
  const { t } = useTranslation();
  const { updateBox, configuration, selectedBox, container, currentProposal, isSelectionBox } = useStore(selector);

  const selectedBoxIsShape =
    selectedBox?.type === 'shape' || selectedBox?.type === 'line';
  const bgColorDisabled =
    selectedBox &&
    ['title1', 'title2', 'title3', 'title4', 'summary', 'table', ItemTypes.TABLE_VARIABLE].includes(
      selectedBox.type
    );

  const toggleBgColor = (newColor) => {
    if (selectedBoxIsShape) {
      updateBox(selectedBox.id, (box) => {
        const colorStr = configuration.colors.find(
          (color) => color.label === newColor
        ).style;
        if (box.color) {
          box.color = colorStr;
        } else if (box.content) {
          box.content.color = colorStr;
        } else {
          box.backgroundColor = colorStr;
        }
      });
    } else {
      onChangeColor(
        selectedBox.id,
        updateBox,
        configuration
      )(newColor);
    }
  }

  const unCommonBox =
    !selectedBox ||
    ['text', 'title1', 'title2', 'title3', 'title4', 'summary', 'line_break', ItemTypes.GROUP_BOXES].includes(selectedBox?.type) ||
    isAVariableBox(selectedBox?.type)

  const backgroundColorPickerDisabled = useCallback(() => {
    if (isSelectionBox) return true
    if (!container?.editable &&
      container?.hasOwnProperty('editable') &&
      currentProposal.id !== null) {
      return true
    } else return unCommonBox || bgColorDisabled
  }, [isSelectionBox, container, currentProposal?.id, unCommonBox, bgColorDisabled])


  const shouldHideButton = (box, isTextBoxCheck, managerDisabled) => {
    if (!box) return true;
    if (!isTextBoxCheck(box?.type)) return managerDisabled();
    if (_.isEmpty(box?.border) && _.isEmpty(box?.backgroundColor)) return true;
    return box?.backgroundColor?.label === "transparent" && box?.border?.color?.label === "transparent"
      ? managerDisabled()
      : false;
  };

  let hideButton = shouldHideButton(selectedBox, isATextBox, backgroundColorPickerDisabled);

  return (
    <>
      <Separator hidden={backgroundColorPickerDisabled()} />
      <NavbarListButton
        key="bgColorList"
        title={
          selectedBox?.type === 'shape'
            ? 'Couleur de forme'
            : t('background-color')
        }
        hidden={hideButton}
        icon={< FontAwesomeIcon icon={icon({ name: 'fill-drip', style: 'light' })} style={iconButtonStyle()} />}
        onListElementClick={toggleBgColor}
        listElements={selectedBoxIsShape ? filterSameColor(configuration.colors.filter((c) => c.label !== 'transparent')) : filterSameColor(configuration.colors)}
        selectedElement={selectedBox ? (selectedBoxIsShape ? configuration.colors.find((c) => c.style === (selectedBox.color ?? selectedBox.content.color)) : selectedBox.backgroundColor) : null}
      />
      <Separator hidden={hideButton} />
    </>
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useRef } from 'react';
import { useStore } from '../../store/store';
import { filterSameColor, iconButtonStyle } from '../../utils/styles';
import { NavbarListButton } from '../Layout/NavbarListButton';

const selector = ({ configuration }) => ({
  configuration,
});

const HighlightControl = (props) => {
  const { configuration: { highlightColors } } = useStore(selector);
  var currentStyle = props?.editorState?.getCurrentInlineStyle(props.editorState.getSelection());
  let currentColor = highlightColors.find((color) => currentStyle?.has(`HIGHLIGHT_${color.label}`)) || highlightColors.find((c) => c.label === 'transparent');
  const colorControlRef = useRef(null);

  return (
    <NavbarListButton
      title={'Surligner'}
      disabled={false}
      icon={< FontAwesomeIcon icon={icon({ name: 'highlighter', style: 'light' })} style={iconButtonStyle()} />}
      onListElementClick={props.onToggle}
      listElements={filterSameColor(props.colors)}
      selectedElement={currentColor}
      listRef={colorControlRef}
    />
  );
};

export default HighlightControl;

import { message } from 'antd';
import { produce } from 'immer';
import { formatPagesPreview, mutateClosePreview } from '../utils/pagesPreview';

export const pdfActions = (set, get) => ({
  handlePdfReponse: (data) => {
    set(
      produce((draft) => {
        try {
          if (data?.pdf_url) {
            if (draft.PDFRenderState === 'archive')
              message.success({
                content: 'Le PDF a bien été enregistré.',
                key: 'pdfKey',
              });
            else
              message.success({
                content: 'Le PDF a bien été généré.',
                key: 'pdfKey',
              });
            const newWindow = window.open(
              data.pdf_url,
              '_blank',
              'noopener,noreferrer'
            );
            if (newWindow) {
              newWindow.focus();
              newWindow.opener = null;
            }
          } else if (data?.pdf_error) {
            message.error({
              content: `Une erreur est survenu lors de la génération ${
                draft.PDFRenderState === 'preview' ? "de l'aperçu" : 'du PDF'
              }.`,
              key: 'pdfKey',
            });
            mutateClosePreview(draft);
          } else if (data.pages) {
            if (typeof draft.pagesPreviewExcludedIdx === 'number') {
              let pagesPreviewExcluded = [];
              pagesPreviewExcluded = get().pagesPreview.filter(
                (_, idx) => idx < draft.pagesPreviewExcludedIdx
              );
              draft.pagesPreview = [
                ...pagesPreviewExcluded,
                ...formatPagesPreview({
                  pages: data.pages,
                }),
              ];
            } else {
              draft.pagesPreview = formatPagesPreview({
                pages: data.pages,
              });
            }
            message.success({
              content: "L'aperçu a bien été généré.",
              key: 'pdfKey',
            });
            draft.isPreviewLoading = false;
          }
          draft.PDFRenderState = false;
        } catch (err) {
          message.error({
            content: 'Une erreur est survenu lors de la génération du PDF.',
            key: 'pdfKey',
          });
          draft.PDFRenderState = false;
          mutateClosePreview(draft);
        }
      }),
      false,
      `handlePdfReponse has ${data?.pdf_url ? 'succeed' : 'failed'}`
    );
  },
});

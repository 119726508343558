import { Tooltip, Upload, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { convertContentToRawText, decorator, handleBeforeInput } from '../RichEditor/utils';
import { transformTextToEditorState } from '../../utils/dataSync';
import { useStore } from '../../store/store';
import { UploadOutlined } from '@ant-design/icons';
import { ContentState, Editor, EditorState, convertToRaw } from 'draft-js';

import { convertFileToBase64 } from '../../utils/file';
import * as documentDatasApi from '../../requests/documentDatas';
import { pageWidth } from '../../constants/gridConfig';
import { maxContainerHeight } from '../../utils/containers';
import ReactDOM from 'react-dom';
import { MASKS_TYPE, MENU_ANCESTORS, viewerTypes } from '../../constants/constants';
import { debounce } from 'lodash';
import { convertPadding, getCellStyle } from '../../utils/table';
import ColumnResizer from './ColumnResizer';
import FloatyTextStyling from './FloatyTextStyling';
import EditorWrapper from '../common/EditorWrapper';
import { useStyleMap } from '../../hooks';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { EditorProvider } from '../../contexts/EditorContext';
import ControlRow from './ControlRow';
import { usePreviewContext } from '../../contexts/PreviewContext';

const Cell = ({
  handleClickInTable,
  type,
  cellContent,
  box,
  tableIsSelected,
  boxId,
  columnKey,
  rowKey,
  theadHeight,
  tableStyle,
  align,
  isEven,
  rotation,
  setColumnSizeVars,
  nextColKey,
  computeMaxColumnWidth,
  isLastColumn,
  drawMode,
  columnLineHovered,
  setColumnLineHovered,
  rowLineHovered,
  setRowLineHovered,
  isFirstRow,
  isLastRow,
  isFirstColumn,
  controlRowHovered,
  setControlRowHovered,
  controlRowClicked,
  setControlRowClicked,
  nextRowKey,
  zIndex,
  placementIndex
}) => {
  const { fromViewer } = usePreviewContext();
  const styleMap = useStyleMap()
  const updateBox = useStore(({ updateBox }) => updateBox)
  const selectedBoxId = useStore(({ selectedBoxId }) => selectedBoxId)
  const setEventManagerEnabled = useStore(({ setEventManagerEnabled }) => setEventManagerEnabled)
  const fromPdf = useStore(({ fromPdf }) => fromPdf)
  const currentDocument = useStore(({ currentDocument }) => currentDocument)
  const currentProposal = useStore(({ currentProposal }) => currentProposal)
  const landscape = useStore(({ landscape }) => landscape)
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const configuration = useStore(({ configuration }) => configuration)

  const [editorState, setEditorState] = useState(transformTextToEditorState(cellContent));
  const [isSelected, setIsSelected] = useState(false)


  useEffect(() => {
    if (!isSelected) {
      setEditorState(transformTextToEditorState(cellContent))
    }
  }, [cellContent, isSelected]);

  const inputRef = useRef()
  const parentRowType = "cell"
  const isControlRowHovered = controlRowHovered.parentRowId === rowKey &&
    controlRowHovered.parentRowType === parentRowType
  const isControlRowClicked = controlRowClicked.parentRowId === rowKey &&
    controlRowClicked.parentRowType === parentRowType
  const isControlRowAfterHovered = nextRowKey && controlRowHovered.parentRowId === nextRowKey &&
    controlRowHovered.parentRowType === parentRowType
  const isControlRowAfterClicked = nextRowKey && controlRowClicked.parentRowId === nextRowKey &&
    controlRowClicked.parentRowType === parentRowType
  const isControlRowClickedToDelete = isControlRowClicked && controlRowClicked.isToDelete

  const isColumnControlRowHovered = controlRowHovered.parentRowId === columnKey &&
    controlRowHovered.parentRowType === "header"

  const isColumnControlRowClicked = controlRowClicked.parentRowId === columnKey &&
    controlRowClicked.parentRowType === "header"
  const isColumnControlRowClickedToDelete = isColumnControlRowClicked && controlRowClicked.isToDelete

  const style = getCellStyle({
    tableStyle,
    isEven,
    configuration,
    columnKey,
    isLastColumn,
  })

  const handleUploadImg = useCallback(async (file) => {
    const img = await convertFileToBase64(file.file);
    const imgEl = document.createElement('img');
    let id = 0;
    if (currentDocument.type === 'template') {
      id = currentDocument.id;
    } else {
      id = currentProposal.id;
    }
    documentDatasApi.postImage(id, currentDocument.type, {
      img: /base64,(.+)/.exec(img)[1],
      filename: file.file.name,
    }).then((res) => {
      imgEl.onload = () => {
        const newEditorState = EditorState.createWithContent(
          ContentState.createFromText(res.url),
          decorator
        )
        updateBox(boxId, (box) => {
          box.content.data.find((row) => row.key === rowKey)[columnKey] = JSON.stringify(
            convertToRaw(newEditorState.getCurrentContent()));
        });
        setEditorState(newEditorState)
      };
      imgEl.src = img;
    }).catch((err) => {
      if (err.response.status === 422)
        message.error({
          content: "La taille de l'image ne doit pas dépasser 1Mo",
          key: 422,
        })
    });
  }, [boxId, columnKey, currentDocument.id, currentDocument.type, currentProposal.id, rowKey, updateBox]);

  const onChangeCellText = useCallback(
    async (value) => {
      if (type === 'image') {
        if (!value) {
          const emptyEditorState = EditorState.createEmpty(decorator)
          updateBox(boxId, (box) => {
            const row = box.content.data.find((row) => row.key === rowKey)
            if (row)
              row[columnKey] = JSON.stringify(
                convertToRaw(emptyEditorState.getCurrentContent()));
          });
          setEditorState(emptyEditorState)
          return;
        }
        handleUploadImg(value)
        return;
      }
      updateBox(boxId, (box) => {
        const row = box.content.data.find((row) => row.key === rowKey)
        if (row)
          row[columnKey] = value;
      });
    },
    [boxId, updateBox, rowKey, columnKey, type, handleUploadImg]
  );

  const focus = useCallback(() => {
    setIsSelected(true)
    setEventManagerEnabled(false);
    inputRef.current?.focus()
  }, [setEventManagerEnabled])

  const handleFocus = (e) => {
    if (tableIsSelected && !isSelected) {
      e.preventDefault();
      focus()
    }
  };

  useEffect(() => {
    if (box.selectedItem?.rowKey === rowKey &&
      box.selectedItem?.columnKey === columnKey &&
      box.selectedItem?.type === 'data' &&
      selectedBoxId === boxId) {
      focus()
    }
  }, [box.selectedItem?.columnKey, box.selectedItem?.rowKey, box.selectedItem?.type, columnKey, focus, rowKey, updateBox, boxId, box.selectedItem, selectedBoxId]);

  const handleChange = (e) => setEditorState(e);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = useCallback(debounce((editorState) => {
    onChangeCellText(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  }, 100), [onChangeCellText]);

  useEffect(() => {
    if (tableIsSelected && type !== "image" && isSelected) {
      save(editorState);
    }
  }, [editorState, save, tableIsSelected, type, isSelected]);

  const { padding } = convertPadding(tableStyle.size);

  useEffect(() => {
    function handleClickOutside(event) {
      if (isSelected && ref.current && !ref.current.contains(event.target) && type !== "image" &&
        !(event.relatedTarget && (event.relatedTarget.className === "variable-selection-line" || event.relatedTarget.closest(".font-family-selector")))
      ) {
        save(editorState)
        setIsSelected(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editorState, save, isSelected, type]);

  const ref = useRef()
  const menuAncestorIds = useMemo(() => [boxId, columnKey, rowKey], [boxId, columnKey, rowKey])

  const handleOnMouseOver = (fromTop) => {
    setRowLineHovered({ rowId: rowKey, fromTop })
  }

  const handleOnClick = () => {
    setRowLineHovered((old) => ({ ...old, isClicked: true }))
  }

  const handleOnMouseLeave = () => {
    setRowLineHovered({ rowId: null, fromTop: null })
  }

  const setParentLineHovered = useCallback(({ isHovered, isLeftButton }) => {
    if (!isHovered) {
      setRowLineHovered({ rowId: null, fromTop: null })
      return
    }
    if (isLeftButton) {
      setRowLineHovered({ rowId: rowKey, fromTop: true })
      return
    }
    setRowLineHovered({ rowId: rowKey, fromTop: false })
  }, [rowKey, setRowLineHovered])

  return (
    <td style={style}
      onClick={(e) => {
        handleClickInTable({ rowKey, columnKey, type: 'data' })(e)
        handleFocus(e)
      }}
      ref={ref}
      className={`${columnLineHovered.headerId === columnKey ? "column-line-hovered" : ""}
      ${columnLineHovered.fromLeft ? "is-first-column" : ""}
      ${rowLineHovered.rowId === rowKey ? "row-line-hovered" : ""}
      ${rowLineHovered.fromTop ? "is-first-row" : ""}
      ${(isControlRowHovered || isColumnControlRowHovered || isColumnControlRowClicked || isControlRowClicked) ? "table-border-hovered" : ""}
      ${isColumnControlRowClicked || isControlRowClicked ? "table-border-clicked" : ""}
      ${isControlRowClickedToDelete || isColumnControlRowClickedToDelete ? "table-border-clicked-to-delete" : ""}
      `}
    >
      {fromViewer !== viewerTypes.PREVIEW && <>
        {tableIsSelected && isFirstColumn &&
          <ControlRow
            isFirstElement={isFirstRow}
            isLastElement={isLastRow}
            isFromHeader={false}
            setControlRowHovered={setControlRowHovered}
            setControlRowClicked={setControlRowClicked}
            parentRowId={rowKey}
            parentRowType={parentRowType}
            isControlRowHovered={isControlRowHovered}
            isControlRowClicked={isControlRowClicked}
            isControlRowAfterHovered={isControlRowAfterHovered}
            isControlRowAfterClicked={isControlRowAfterClicked}
            setParentLineHovered={setParentLineHovered}
            rowLineHovered={rowLineHovered}
            zIndex={zIndex}
            boxId={boxId}
            placementIndex={placementIndex}
            parentKey={rowKey}
          />}
        {isFirstRow && <div
          onMouseOver={() => handleOnMouseOver(true)}
          onMouseLeave={handleOnMouseLeave}
          onClick={handleOnClick}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: "100%",
            height: 4,
            zIndex: 1,
            transform: "translateY(calc(-2px - 1px))"
          }} />}
        <div
          onMouseOver={() => handleOnMouseOver(false)}
          onMouseLeave={handleOnMouseLeave}
          onClick={handleOnClick}
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: "100%",
            height: 4,
            zIndex: 1,
            transform: "translateY(calc(2px + 1px))"
          }} />
      </>}
      {type === 'image' && <Upload
        name='img du chiffrage'
        showUploadList={false}
        customRequest={(e) => {
          onChangeCellText(e);
        }}
      >
        {convertContentToRawText(editorState)?.trim() !== '' ? (
          <div style={{
            position: 'relative',
            zIndex: tableIsSelected ? 1000 : 'auto',
            padding
          }}>
            {!fromPdf && fromViewer !== viewerTypes.PREVIEW && <span
              onClick={(e) => {
                e.stopPropagation();
                onChangeCellText('');
              }}
              className='--hide-from-pdf'
              style={{
                position: 'absolute',
                top: 0,
                left: 5,
                cursor: 'pointer',
                color: 'red',
                fontSize: 8,
              }}
            >
              &#10060;
            </span>}
            <img
              src={convertContentToRawText(editorState).replace('http://', 'https://')}
              alt='img du chiffrage'
              style={{ width: '100%' }}
            />
          </div>
        ) : (
          <div
            style={{
              margin: 'auto',
              height: '100%',
              width: style.width,
              textAlign: 'center',
              position: 'relative',
              zIndex: tableIsSelected ? 1000 : 'auto',
              overflow: 'hidden',
            }}
          >
            <Tooltip
              title='Télécharger une image'
              placement='bottom'
              mouseLeaveDelay='0'
            >
              {!fromPdf && fromViewer !== viewerTypes.PREVIEW && <UploadOutlined
                style={{ fontSize: '24px' }}
                className='--hide-from-pdf'
              />}
            </Tooltip>
          </div>
        )}
      </Upload>}
      {type !== 'image' && <>
        <div style={{
          margin: 0,
          backgroundColor: 'transparent',
          zIndex: tableIsSelected ? 1000 : 'auto',
          border: 'none',
          color: style.color,
          padding,
          minHeight: 32,
          fontSize: style.fontSize,
          fontFamily: style?.fontFamily,
          maxHeight: landscape ? pageWidth : maxContainerHeight({ header, footer }) - theadHeight - 10,
          overflow: 'hidden',
          lineHeight: 1.5714285714285714,
        }}
        >
          <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
            <EditorWrapper
              boxId={`${boxId}-${columnKey}-${rowKey}`}
              editorState={editorState}
            >
              <Editor
                stripPastedStyles={true}
                handleBeforeInput={(chars, newEditorState) => handleBeforeInput(
                  {
                    chars,
                    editorState: newEditorState,
                    setEditorState
                  }
                )}
                editorState={editorState}
                onChange={handleChange}
                ref={inputRef}
                textAlignment={align}
                readOnly={!isSelected}
                customStyleMap={styleMap}
                onBlur={() => {
                  save(editorState);
                  updateBox(boxId, (box) => {
                    box.selectedItem = undefined
                  }
                  );
                }
                }
              />
            </EditorWrapper>
          </EditorProvider>
        </div>
        {tableIsSelected &&
          ReactDOM.createPortal(
            <ContextualMenu
              editorState={editorState}
              setEditorState={setEditorState}
              menuAncestorType={MENU_ANCESTORS.BOX.cell}
              menuAncestorIds={menuAncestorIds}
            />,
            document.getElementById('SelectionVariablePortal')
          )}
      </>}
      {tableIsSelected && isSelected &&
        ReactDOM.createPortal(
          <FloatyTextStyling
            editorState={editorState}
            setEditorState={setEditorState}
            defaultFontSize={style?.fontSize}
            defaultFontFamily={style?.fontFamily}
            defaultColor={tableStyle.customCellTextColor}
          />,
          document.getElementById('SelectionVariablePortal')
        )}
      {!isLastColumn && fromViewer !== viewerTypes.PREVIEW && <ColumnResizer
        boxId={boxId}
        rotation={rotation}
        setColumnSizeVars={setColumnSizeVars}
        columnKey={columnKey}
        nextColKey={nextColKey}
        computeMaxColumnWidth={computeMaxColumnWidth}
        resizeEnabled={!isLastColumn && (!drawMode || tableIsSelected)}
        setColumnLineHovered={setColumnLineHovered}
      />}
    </td>
  )
}

export default Cell

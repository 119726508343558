import { produce } from 'immer';
import { mutateClosePreview } from '../utils/pagesPreview';

export const pagesPreviewActions = (set, get) => ({
  closePreview: () => {
    set(
      produce((draft) => {
        mutateClosePreview(draft);
      }),
      false,
      `close preview`
    );
  },
  appendPages: (containerIndex) => {
    const container = get().containers[containerIndex];
    const containerInPagesPreviewExcluded = get().pagesPreview.find((page) =>
      page.printedContainers.some((c) => c.id === container.id)
    ).printedContainers;
    const pagesPreviewExcludedIdx = get().pagesPreview.findIndex((page) =>
      containerInPagesPreviewExcluded.some((c) =>
        page.printedContainers.some((pC) => pC.id === c.id)
      )
    );
    set(
      produce((draft) => {
        draft.pagesPreviewExcludedIdx = pagesPreviewExcludedIdx;
      }),
      false,
      `append page after ${pagesPreviewExcludedIdx}`
    );
    get().saveAndGeneratePdf('preview', pagesPreviewExcludedIdx);
  },
});

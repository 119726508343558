import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import {
  boxActionColor,
} from '../../utils/styles';
// Common
import './Utils.css';
import { defaultZoom, dynamicZoom, ItemTypes, MASKS_TYPE, viewerTypes } from '../../constants/constants';
import RichEditor from '../RichEditor';
import Image from '../Image/Image';
import Line from '../Line';
import Table from '../Table';
import Title from '../Title/Title';
import Shape from '../Shape';
import Summary from '../Summary';
import LineBreak from './LineBreak';
import { isAnUrl } from '../../utils/browser';
import CheckboxsVariable from './CheckboxsVariable';
import SelectorVariable from './SelectorVariable';
import { isAWritableBox, isAImgBox, isATableBox, getGroupBoxesDimensions } from '../../utils/boxes';
import { maxContainerHeight } from '../../utils/containers';
import SpaceBox from './SpaceBox';
import { useHandleBoxSelect } from '../../hooks';
import GroupBoxes from './GroupBoxes';

const getStyles = (drawMode, box, header, footer) => {
  if (box.hideField) {
    return {
      display: 'none'
    }
  }
  if (drawMode || isATableBox(box.type) || box.type === ItemTypes.SUMMARY)
    return {
    }
  return {
    maxHeight: maxContainerHeight({ header, footer }),
    overflow: isAWritableBox(box.type) ? 'hidden' : 'visible'
  }
}

export const selectComponent = (box, drawMode) => {
  switch (box?.type) {
    case ItemTypes.TEXT:
    case ItemTypes.TEXT_VARIABLE:
      return <RichEditor box={box} drawMode={drawMode} />;
    case ItemTypes.TITLE:
    case ItemTypes.TITLE_2:
    case ItemTypes.TITLE_3:
    case ItemTypes.TITLE_4:
      return <Title box={box} drawMode={drawMode} />;
    case ItemTypes.IMG:
    case ItemTypes.IMG_VARIABLE:
      return <Image box={box} />;
    case ItemTypes.SHAPE:
      return <Shape box={box} />;
    case ItemTypes.LINE:
      return <Line box={box} />;
    case ItemTypes.TABLE:
    case ItemTypes.TABLE_VARIABLE:
      return <Table box={box} drawMode={drawMode} />;
    case ItemTypes.SUMMARY:
      return <Summary box={box} />;
    case ItemTypes.LINE_BREAK:
      return <LineBreak box={box} />;
    case ItemTypes.CHECKBOXS_VARIABLE:
    case ItemTypes.QUESTION:
      return <CheckboxsVariable box={box} />;
    case ItemTypes.SELECTOR_VARIABLE:
      return <SelectorVariable box={box} />;
    case ItemTypes.SPACE:
      return <SpaceBox box={box} />;
    case ItemTypes.GROUP_BOXES:
      return <GroupBoxes box={box} />;
    default:
      return null;
  }
};

const selector =
  (id) =>
    ({
      handleSelectBox,
      selectedBoxId,
      groupSelection,
      setBoxMovable,
      slider,
      boxes,
      updateBox,
      masks,
      containers,
      currentProposal,
      fromPdf,
      configuration,
      landscape,
      columns,
      boxVariableSelectedId
    }) => {
      const allBoxes = [
        ...boxes,
        ...masks.map((m) => m.boxes).flat()
      ]
      const box = allBoxes.find((b) => b.id === id);
      const container = containers.find(container => container.columnsIds.includes(box.columnId))
      const maskType = masks.find((m) => m.id === MASKS_TYPE.HEADER.id)?.editableMask;
      const maskTypeEdiatble = typeof maskType !== 'boolean' ? true : maskType;
      return {
        handleSelectBox,
        isSelected: selectedBoxId === id,
        isInSelectedGroup: groupSelection.includes(id),
        setBoxMovable,
        isSlided: slider.boxes.includes(id),
        box: box,
        updateBox,
        container: container,
        currentProposal,
        fromPdf,
        maskTypeEdiatble: maskTypeEdiatble,
        configuration,
        landscape,
        masks,
        columns,
        boxesInGroup: box.type === ItemTypes.GROUP_BOXES ? allBoxes.filter((b) => box.boxIds.includes(b.id)) : undefined,
        boxVariableSelectedId
      }
    };

const Box = ({ id, width, height, drawMode, fromViewer }) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const {
    handleSelectBox,
    isSelected,
    isInSelectedGroup,
    isSlided,
    box,
    updateBox,
    container,
    currentProposal,
    fromPdf,
    maskTypeEdiatble,
    configuration,
    boxesInGroup,
    boxVariableSelectedId
  } = useStore(selector(id));

  const boxRef = React.useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  const measureBox = (suffix = 'from_measuring_effect') => {
    setTimeout(() => {
      if (boxRef.current) {
        const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;
        const { top, left } = boxRef.current?.getBoundingClientRect();
        const { width, height } = boxRef.current?.getBoundingClientRect();
        const { top: parentTop, left: parentLeft } = boxRef.current.parentElement.parentElement.getBoundingClientRect();
        const boxLeft = (left / zoomFactor) - parentLeft;
        const boxTop = (top / zoomFactor) - parentTop;

        updateBox(
          box.id,
          (box) => {
            box.clientTop = boxTop;
            box.clientLeft = boxLeft;
            box.clientWidth = width / zoomFactor;
            box.clientHeight = height / zoomFactor;
          },
          { actionNameSuffix: suffix }
        );
      }
    }, 100);
  }

  // }, [box, box.top, box.left, updateBox])

  useEffect(() => {
    const shouldMeasure = box.type === ItemTypes.GROUP_BOXES && box.shouldMeasure && !boxVariableSelectedId
    if (shouldMeasure) {
      const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;
      const { width, height, left, top } = getGroupBoxesDimensions(
        boxesInGroup,
        zoomFactor,
        box.rotation
      );
      if (box.left !== left || box.top !== top || box.height !== height || box.width !== width) {
        updateBox(
          box.id,
          (box) => {
            box.left = left
            box.top = top
            box.height = height
            box.width = width
            delete box.shouldMeasure
          },
        );
        if (box.top !== top)
          boxesInGroup.forEach((b) => {
            updateBox(
              b.id,
              (box) => {
                box.groupTop = top
              },
              {
                shouldNotMeasureGroupBox: true
              }
            );
          })
      } else {
        updateBox(
          box.id,
          (box) => {
            delete box.shouldMeasure
          },
        );
      }
    }
  }, [box.height, box.id, box.left, box.rotation, box.shouldMeasure, box.top, box.type, box.width, boxesInGroup, configuration?.zoom, updateBox, boxVariableSelectedId]);

  useEffect(() => {
    const shouldMeasure = isSelected || isInSelectedGroup || isSlided;
    if (shouldMeasure) {
      setTimeout(() => {
        measureBox();
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, isInSelectedGroup, isSlided, box.rotation]);

  useEffect(() => {
    if (box?.alignToken) {
      setTimeout(() => {
        measureBox('from_align_token_effect');
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [box?.alignToken]);

  const handleBoxSelect = useHandleBoxSelect({ fromViewer, box })

  const editableContainerHoverRule = useCallback(() => {
    if (fromViewer === viewerTypes.PAGENUMBER || fromViewer === viewerTypes.DMZ || fromViewer === viewerTypes.PREVIEW) return false
    if (currentProposal.id === null) {
      return true
    } else {
      if ((box.maskId === "header" || box.maskId === "footer") && !maskTypeEdiatble && box.type !== "img") return false
      if (container?.editable || !container?.hasOwnProperty("editable")) {
        return true
      } else {
        if (box.type === "img") {
          return true
        } else {
          return false
        }
      }
    }
  }, [container, currentProposal, box]);

  if (!box) { return null }
  return (
    <div
      ref={boxRef}
      id={`box-${box?.id ? box.id : 'new'}`}
      className={isSlided ? 'box-sliding' : ''}
      onMouseEnter={() => setIsHovered(editableContainerHoverRule())}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(event) => {
        handleBoxSelect(event)
      }}
      style={{
        outline: `2px solid ${boxActionColor({
          selected: isSelected,
          inGroup: isInSelectedGroup,
          hovered: isHovered
        })}`,
        zIndex: boxesInGroup ? (Math.max(...boxesInGroup.map((b) => ~~b.zIndex)) + ~~box?.zIndex) : box?.zIndex,
        ...((fromPdf || fromViewer === viewerTypes.PREVIEW) && isAImgBox(box?.type) &&
          !isAnUrl(box?.content?.src) ? { visibility: 'hidden' } : {}),
        ...getStyles(drawMode, box, header, footer)
      }}
    >
      {selectComponent({
        ...box,
        fromViewer,
        width: width || box.width,
        height: height || box.height,
      }, drawMode)}
    </div>
  );
};

export default Box;

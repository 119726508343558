import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useStore } from '../../store/store'
import ContainerWrapper from '../Container/ContainerWrapper'
import { navbarHeight, pageHeight, pageWidth } from '../../constants/gridConfig'
import { Virtuoso } from 'react-virtuoso'
import { MASKS_TYPE, viewerTypes } from '../../constants/constants'
import Container from '../Container/Container'
import { PreviewProvider } from '../../contexts/PreviewContext'
import { maxContainerHeight } from '../../utils/containers'

const Item = React.memo(({ index, fromLateralSide, scrollToItemLateral }) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const pagesPreview = useStore(({ pagesPreview }) => pagesPreview)
  const configuration = useStore(({ configuration }) => configuration)
  const pageInfos = pagesPreview[index]
  const isFullHeight = pagesPreview[index].isFullHeight
  const printedContainers = pageInfos.printedContainers
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let maxRatio = 0;

        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.51 && entry.intersectionRatio > maxRatio) {
            maxRatio = entry.intersectionRatio;
            scrollToItemLateral(index)
          }
        });
      },
      {
        threshold: 0.51,
      }
    );

    if (!fromLateralSide)
      observer.observe(ref.current);
    return () => {
      if (!fromLateralSide)
        observer.disconnect();
    }
  }, [fromLateralSide, scrollToItemLateral, index])

  const renderContainer = (containerInfos, isLast) => {
    return <PreviewProvider startFrom={containerInfos.startFrom} duppedBreakIdx={containerInfos.duppedBreakIdx} key={`${containerInfos.id}-${containerInfos.duppedBreakIdx}-${containerInfos.startFrom}`} >
      <ContainerWrapper
        containerId={containerInfos.id}
        fromViewer={viewerTypes.PREVIEW}
        hideAdder={isLast || fromLateralSide}
        type='container' />
    </PreviewProvider>
  }

  return <div
    ref={ref}
    style={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: "white",
    }}>
    {!isFullHeight && <Container id={header.id} key={header.id} fromViewer={viewerTypes.PREVIEW} />}
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: isFullHeight ? pageHeight : maxContainerHeight({ header, footer }),
      gap: fromLateralSide ? configuration.lineSpacing : 0,
    }}>
      {printedContainers.map((containerInfos, idx) => renderContainer(containerInfos, idx === printedContainers.length - 1))}
    </div>
    {!isFullHeight && <Container id={footer.id} key={footer.id} fromViewer={viewerTypes.PREVIEW} />}
  </div>
})


const ListLateral = React.forwardRef((props, ref) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: 'center',
        backgroundColor: "#6d6d7c",
        borderRight: "1px solid black",
        paddingTop: 20,
        minHeight: "100%"
      }}>
      <div
        {...props}
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: 'center',
          ...props.style
        }} />
    </div>
  )
})

const List = React.forwardRef((props, ref) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: 'center',
        backgroundColor: "#323232",
        paddingTop: 20,
        minHeight: '100%'
      }}>
      <div
        {...props}
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: 'center',
          ...props.style
        }} />
    </div>
  )
})

const PreviewOverlay = () => {
  const previewRenderState = useStore(({ previewRenderState }) => previewRenderState)
  const pagesPreview = useStore(({ pagesPreview }) => pagesPreview)
  const landscape = useStore(({ landscape }) => landscape)
  const configuration = useStore(({ configuration }) => configuration)
  const [pageSelected, setPageSelected] = useState()
  const listRef = useRef();
  const listLateralRef = useRef();
  const margin = 80 + 220 + 200; // Marges totales
  const availableWidth = window.innerWidth - margin;
  const zoom1 = availableWidth / (landscape ? pageHeight : pageWidth);
  const zoom2 = (window.innerHeight - navbarHeight) / (pageHeight + 50);
  const zoom = Math.min(zoom1, zoom2);

  const scrollToItem = useCallback((index) => {
    listRef.current.scrollToIndex(index);
  }, []);

  const scrollToItemLateral = useCallback((index) => {
    setPageSelected(index)
    listLateralRef.current.scrollToIndex(index);
  }, []);

  const renderPage = (index) => {
    return <div
      style={{
        paddingBottom: 20,
      }}>
      <div style={{
        width: landscape ? pageHeight : pageWidth,
        zoom: `${zoom}`,
      }}
      >
        <Item index={index} scrollToItemLateral={scrollToItemLateral} />
      </div>
    </div>
  }

  const renderPageLateralSide = (index) => {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 30,
    }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => {
          setPageSelected(index)
          scrollToItem(index)
        }}>
        <div style={{
          padding: 10,
          backgroundColor: pageSelected === index ? "rgb(255 255 255 / 50%)" : "transparent",
          borderRadius: 6,
        }}>
          <div style={{
            width: landscape ? pageHeight : pageWidth,
            zoom: '10%',
          }}>
            <Item index={index} fromLateralSide />
          </div>
        </div>
        <div style={{ height: 2 }} />
        <div style={{
          color: 'white',
          padding: 5,
          lineHeight: 1,
          backgroundColor: pageSelected === index ? "#0751b6" : "transparent",
          borderRadius: 6,
        }}>
          {index + 1}
        </div>
      </div>
    </div>
  }

  if (!previewRenderState || !pagesPreview || pagesPreview.length === 0) return <div
    id='PreviewPortal'
  />

  return (
    <div style={{
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(6px)',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      position: 'fixed',
      top: 0,
      bottom: 0,
      zIndex: 1000
    }}>
      {(navigator.userAgent.indexOf('Chrome') > -1 || !(navigator.userAgent.indexOf("Safari") > -1)) && <div style={{
        position: 'fixed',
        height: "100%",
        right: 220 + (landscape ? pageHeight : pageWidth) * zoom + 80,
        top: navbarHeight,
      }}>
        <Virtuoso
          ref={listLateralRef}
          style={{
            width: 200,
            height: window.innerHeight - navbarHeight,
            color: configuration.baseFontColor?.style,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
          className='no-scrollbar'
          totalCount={pagesPreview.length}
          itemContent={renderPageLateralSide}
          defaultItemHeight={((landscape ? pageWidth : pageHeight) + 20)}
          components={{ List: ListLateral }}
        />
      </div>}
      <div style={{
        position: 'fixed',
        height: "100%",
        right: 220,
        top: navbarHeight,
      }}>
        <Virtuoso
          ref={listRef}
          style={{
            width: (landscape ? pageHeight : pageWidth) * zoom + 80,
            height: window.innerHeight - navbarHeight,
            color: configuration.baseFontColor?.style,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          }}
          className='no-scrollbar'
          totalCount={pagesPreview.length}
          itemContent={renderPage}
          defaultItemHeight={((landscape ? pageWidth : pageHeight) + 20)}
          components={{ List }}
        />
      </div>
      <div
        id='PreviewPortal'
      />
    </div>
  )
}

export default PreviewOverlay

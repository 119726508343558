import React, { useState, useCallback, useEffect } from 'react';
import { useStore } from '../store/store';
import Loader from '../Loader';

const leftShownStatus = {
  SUMMARY: "summary",
  VARIABLES: "variables"
}

const defaultWidth = 220
const isDefaultCollapsed = false;

const defaultProps = {
  width: defaultWidth,
  left: {
    collapsed: isDefaultCollapsed,
    shownStatus: leftShownStatus["SUMMARY"]
  },
  right: {
    collapsed: isDefaultCollapsed,
  },
}

const OverlayContext = React.createContext({
  ...defaultProps,
  handleOverlayCollapse: (side, collapsed) => null,
  updateLeftShownStatus: (status, boxId) => null
});

const useOverlay = () => {
  const context = React.useContext(OverlayContext);

  if (!context) {
    throw new Error(`useOverlay must be used within a OverlayProvider`);
  }

  return context;
};

const selector = ({ handleBoxVariableSelectedId }) => ({
  handleBoxVariableSelectedId
})

const OverlayProvider = ({ children }) => {
  const { handleBoxVariableSelectedId } = useStore(selector)
  const areSectionsActivating = useStore(({ areSectionsActivating }) => areSectionsActivating)
  const isDocumentLoading = useStore(({ isDocumentLoading }) => isDocumentLoading)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  const defaultDocumentView = useStore(({ configuration }) => configuration.defaultDocumentView)
  const isPreviewLoading = useStore(({ isPreviewLoading }) => isPreviewLoading)


  const [overlayProps, setOverlayProps] = useState({
    ...defaultProps,
  });

  useEffect(() => {
    if (!isTemplate) {
      setOverlayProps((old) => ({
        ...old,
        left: {
          ...old.left,
          shownStatus: defaultDocumentView === 'form' ? leftShownStatus["VARIABLES"] : leftShownStatus["SUMMARY"]
        },
      }))
    }
  }, [defaultDocumentView, isTemplate])

  const handleOverlayCollapse = useCallback((side, collapsed) => {
    if (side !== "left" && side !== "right") return;

    setOverlayProps((oldProps) => ({
      ...oldProps,
      [side]: {
        ...oldProps[side],
        collapsed
      }
    }))
    if (side === "right")
      handleBoxVariableSelectedId();
  }, [handleBoxVariableSelectedId]);

  const updateLeftShownStatus = useCallback((status, boxId = undefined) => {
    if (!Object.values(leftShownStatus).includes(status)) return;

    setOverlayProps((oldProps) => ({
      ...oldProps,
      left: {
        ...oldProps.left,
        shownStatus: status,
        collapsed: false
      }
    }))
    handleBoxVariableSelectedId(boxId);
  }, [handleBoxVariableSelectedId])

  const value = React.useMemo(
    () => ({ overlayProps, handleOverlayCollapse, updateLeftShownStatus }),
    [overlayProps, handleOverlayCollapse, updateLeftShownStatus]
  );

  return (
    <OverlayContext.Provider value={value}>
      {(areSectionsActivating || isDocumentLoading || isPreviewLoading) &&
        <Loader style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(209, 209, 209, 0.69)',
          width: "100vw",
          height: "100vh",
          position: 'fixed',
          zIndex: 99999,
          top: 0
        }} />
      }
      {children}
    </OverlayContext.Provider>
  );
};

export { OverlayProvider, useOverlay, leftShownStatus };

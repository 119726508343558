import { maxLeftBounds, maxTopBounds } from '../constants/gridConfig';

import {
  BORDER_GRAY,
  PRIMARY_COLOR,
  RESIZING_BORDER,
  BG_BADGE,
  VALID_GREEN,
} from '../constants/constants';
import { marginOnPageButton } from '../constants/gridConfig';
import { boxHeight } from './boxes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export const actionButtonBottomMargin = 8;

export const getBackgroundBorderStyle = (
  backgroundColor,
  border,
  style = {}
) => {
  return {
    ...style,
    width: '100%',
    backgroundClip: 'content-box',
    borderRadius: border?.radius + 'px',
    backgroundColor:
      backgroundColor?.label !== 'transparent' ? backgroundColor?.style : null,
    borderTop:
      border?.top === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderBottom:
      border?.bottom === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderLeft:
      border?.left === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderRight:
      border?.right === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
  };
};

export const navbarElementStyle = {
  padding: 0,
  heigth: 32,
  marginRight: marginOnPageButton,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
};

export const navbarButtonStyle = {
  ...navbarElementStyle,
  width: 32,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: BORDER_GRAY,
  borderRadius: '5px',
  backgroundColor: 'white',
};

export const checkOffset = [-10, 8];
export const checkStyle = {
  fontSize: 14,
  color: VALID_GREEN,
  backgroundColor: BG_BADGE,
  borderRadius: 7,
};

export const iconButtonStyle = (active = false) => ({
  fontSize: 20,
  margin: 0,
  color: active ? PRIMARY_COLOR : 'black',
});

export const getPositionStyles = (box, isDragging) => {
  let { top, left } = box;
  if (left < maxLeftBounds) left = maxLeftBounds;
  if (top < maxTopBounds) top = maxTopBounds;
  let transform = `translate3d(${left}px, ${top}px, 0)`;
  if (isDragging) {
    const offsetMove = getRotationMove(box);
    transform = `translate3d(${left - offsetMove.x}px, ${
      top - offsetMove.y
    }px, 0)`;
  }
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
  };
};

export const getRotationMove = (box) => {
  const { rotation, width } = box;
  if (rotation === 0) {
    return { x: 0, y: 0 };
  }
  const w = width;
  const h = boxHeight(box);
  const aplhaZeroRad = Math.atan(h / w);
  const aplhaOneRad = aplhaZeroRad + (rotation * Math.PI) / 180;
  const hyp = Math.sqrt((w * w) / 4 + (h * h) / 4);
  const xOne = hyp * Math.cos(aplhaOneRad);
  const yOne = hyp * Math.sin(aplhaOneRad);
  return {
    x: xOne - w / 2,
    y: yOne - h / 2,
  };
};

export const getRotationStyle = (rotation) => {
  // let offset = '';
  // if (isDragging) {
  //   const offsetMove = getRotationMove(box);
  //   offset = isDragging ? `translate3d(${offsetMove.x}px, ${offsetMove.y}px, 0) ` : '';
  // }
  const transform = `rotate(${rotation}deg)`;
  return {
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
  };
};

export const getDragStyles = (isDragging, isHovered) => {
  const shouldNotDisplay = isDragging && isHovered;
  return {
    opacity: shouldNotDisplay ? 0 : 1,
    height: shouldNotDisplay ? 0 : '',
  };
};

export const boxActionColor = ({ selected, inGroup, hovered }) => {
  let color;
  switch (true) {
    case selected:
      color = 'transparent';
      break;
    case hovered:
      color = '#BAD6F2';
      break;
    case inGroup:
      color = '#5499DE';
      break;
    default:
      color = 'transparent';
      break;
  }
  // if (!selected && !inGroup && hovered) {
  //   color = "#5499DE";
  // } else if (selected && hovered) {
  //   color = '#BAD6F2';
  // } else if (inGroup && hovered) {
  //   color = '#BAD6F2';
  // } else if (selected) {
  //   color = "#5499DE";
  // } else if (inGroup) {
  //   color = "#5499DE";
  // } else {
  //   color = 'transparent';
  // }
  return color;
};

export const resizeHandlersPresence = ({
  top = false,
  right = false,
  bottom = false,
  left = false,
  topRight = false,
  bottomRight = false,
  bottomLeft = false,
  topLeft = false,
}) => ({
  top,
  right,
  bottom,
  left,
  topRight,
  bottomRight,
  bottomLeft,
  topLeft,
});

export const resizeHandlersStyles = {
  bottom: {
    width: '30px',
    height: '7px',
    marginTop: -3,
    marginLeft: -15,
    border: '1px solid ' + RESIZING_BORDER,
    backgroundColor: 'white',
    borderRadius: '25px',
    left: '50%',
    cursor: 'ns-resize',
    boxSizing: 'border-box',
    zIndex: 100,
    // transform: `rotate(${rotation}deg)`,
  },
  right: {
    width: '7px',
    height: '30px',
    marginTop: -15,
    marginLeft: -3,
    border: '1px solid ' + RESIZING_BORDER,
    backgroundColor: 'white',
    borderRadius: '25px',
    top: '50%',
    cursor: 'ew-resize',
    boxSizing: 'border-box',
    zIndex: 2,
    // transform: `rotate(${rotation}deg)`,
  },
};

export const variableStyle = {
  left: {
    borderRight: '0.5px solid white',
    paddingRight: 8,
  },
  middle: {
    borderRight: '0.5px solid white',
    paddingRight: 8,
    borderLeft: '0.5px solid white',
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: 3,
  },
  right: {
    borderLeft: '0.5px solid white',
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
};

/**
 * Merges a type of styles (tableStyles, selectorStyles, etc.)
 * while reassigning IDs to avoid collisions.
 * @param {Object} configuration - The current draft configuration
 * @param {Object} sectionConfiguration - The section configuration
 * @param {string} sectionId - The unique identifier of the section
 * @param {string} propertyKey - The key of the styles array (e.g., 'tableStyles')
 * @param {string} sectionTitle - The title of the section
 * @param {string} dateTimestamp - The timestamp of the injection
 * @returns {Array} - The merged array of styles
 */
function mergeStylesForProperty({
  configuration,
  sectionConfiguration,
  sectionId,
  sectionTitle,
  dateTimestamp,
  propertyKey,
}) {
  // Existing array in the current configuration
  const existingStyles = configuration[propertyKey] || [];
  let sectionStyles = sectionConfiguration[`${propertyKey}`] || [];
  if (propertyKey !== 'tableStyles') {
    sectionStyles = mutateImportImmutableStyleValue(
      sectionStyles,
      configuration
    );
  }

  // Reassign IDs for styles coming from the section
  const updatedStylesFromSection = sectionStyles.map((originalStyle) => {
    let newId = originalStyle.id;
    newId = `${newId}_section_${sectionId}_time_${dateTimestamp}`;
    return {
      ...originalStyle,
      name: `${originalStyle.name} - (Provient de la section : ${sectionTitle})`,
      id: newId,
    };
  });

  return [...existingStyles, ...updatedStylesFromSection];
}

export const mutateMergeStyles = ({
  draft,
  sectionConfiguration,
  sectionId,
  sectionTitle,
  dateTimestamp,
}) => {
  const mergedArray = [
    ...draft.configuration.colors,
    ...sectionConfiguration.colors
  ];

  const mergedHighlightArray = [
    ...draft.configuration.highlightColors,
    ...sectionConfiguration.highlightColors
  ];
  draft.configuration.colors = mergedArray;
  draft.configuration.highlightColors = mergedHighlightArray;
  draft.configuration.tableStyles = mergeStylesForProperty({
    configuration: draft.configuration,
    sectionConfiguration,
    sectionId,
    sectionTitle,
    dateTimestamp,
    propertyKey: 'tableStyles',
  });
  draft.configuration.checkboxStyles = mergeStylesForProperty({
    configuration: draft.configuration,
    sectionConfiguration,
    sectionId,
    sectionTitle,
    dateTimestamp,
    propertyKey: 'checkboxStyles',
  });
  draft.configuration.selectorStyles = mergeStylesForProperty({
    configuration: draft.configuration,
    sectionConfiguration,
    sectionId,
    sectionTitle,
    dateTimestamp,
    propertyKey: 'selectorStyles',
  });
  draft.configuration.textStyles = mergeStylesForProperty({
    configuration: draft.configuration,
    sectionConfiguration,
    sectionId,
    sectionTitle,
    dateTimestamp,
    propertyKey: 'textStyles',
  });
  const mergedFontFamilies = [
    ...sectionConfiguration.fontFamilyList,
    ...draft.configuration.fontFamilyList,
  ].reduce((unique, item) => {
    const exists = unique.find((x) => x.key === item.key);
    if (!exists) {
      unique.push(item);
    }
    return unique;
  }, []);

  draft.configuration.fontFamilyList = mergedFontFamilies;
};

export const mutateImportImmutableStyleValue = (styles, configuration) => {
  return styles.map((style) => {
    if (style.id !== 0) return style;
    return {
      ...style,
      fontSize: configuration.fontSize,
      color: configuration.baseFontColor,
      fontFamily: configuration.fontFamily,
    };
  });
};

export const mutateMergeTmpStyles = ({
  draft,
  sectionConfiguration,
  sectionId,
  sectionTitle,
  dateTimestamp,
}) => {
  const excludedStyles = new Set([
    'transparent',
    'rgba(0, 0, 0, 0)',
    'rgba(255, 255, 255, 1.0)',
  ]);

  const excludedLabels = new Set(['transparent', 'white', 'black']);

  const fromTmpColors = (draft.tmp?.colors || []).map((item) => ({
    ...item,
    fromTmp: true,
  }));

  const fromSectionColors = sectionConfiguration?.colors?.filter(
    (item) =>
      !excludedStyles.has(item.style) &&
      !excludedLabels.has(item.label)
  );

  const mergedColorArray = [
    ...fromTmpColors,
    ...fromSectionColors.map((item) => ({
      ...item,
      fromTmp: true,
    })),
  ];

  const fromTmpHighlightColors = (draft.tmp?.highlightColors || []).map(
    (item) => ({
      ...item,
      fromTmp: true,
    })
  );

  const fromSectionHighlightColors =
    sectionConfiguration.highlightColors.filter(
      (item) =>
        !excludedStyles.has(item.style) &&
        !excludedLabels.has(item.label)
    );

  const mergedHighlightColorArray = [
    ...fromTmpHighlightColors,
    ...fromSectionHighlightColors.map((item) => ({
      ...item,
      fromTmp: true,
    })),
  ];

  const mergedTableStyleArray = [
    ...(draft.tmp?.tableStyles ? draft.tmp.tableStyles : []),
    ...sectionConfiguration.tableStyles.map((item) => ({
      ...item,
      id: `${item.id}_section_${sectionId}_time_${dateTimestamp}`,
      name: (
        <span>
          {item.name} - ( Provient de la section liée{' '}
          <FontAwesomeIcon icon={icon({ name: 'lock' })} /> : {sectionTitle})
        </span>
      ),
      fromTmp: true,
    })),
  ];

  const mergedCheckboxStyleArray = [
    ...(draft.tmp?.checkboxStyles ? draft.tmp.checkboxStyles : []),
    ...mutateImportImmutableStyleValue(
      sectionConfiguration.checkboxStyles,
      draft.configuration
    ).map((item) => ({
      ...item,
      id: `${item.id}_section_${sectionId}_time_${dateTimestamp}`,
      name: (
        <span>
          {item.name} - ( Provient de la section liée{' '}
          <FontAwesomeIcon icon={icon({ name: 'lock' })} /> : {sectionTitle})
        </span>
      ),
      fromTmp: true,
    })),
  ];

  const mergedSelectorStyleArray = [
    ...(draft.tmp?.selectorStyles ? draft.tmp.selectorStyles : []),
    ...mutateImportImmutableStyleValue(
      sectionConfiguration.selectorStyles,
      draft.configuration
    ).map((item) => ({
      ...item,
      id: `${item.id}_section_${sectionId}_time_${dateTimestamp}`,
      name: (
        <span>
          {item.name} - ( Provient de la section liée{' '}
          <FontAwesomeIcon icon={icon({ name: 'lock' })} /> : {sectionTitle})
        </span>
      ),
      fromTmp: true,
    })),
  ];

  const mergedTextStylesArray = [
    ...(draft.tmp?.textStyles ? draft.tmp.textStyles : []),
    ...mutateImportImmutableStyleValue(
      sectionConfiguration.textStyles,
      draft.configuration
    ).map((item) => ({
      ...item,
      id: `${item.id}_section_${sectionId}_time_${dateTimestamp}`,
      name: (
        <span>
          {item.name} - ( Provient de la section liée{' '}
          <FontAwesomeIcon icon={icon({ name: 'lock' })} /> : {sectionTitle})
        </span>
      ),
      fromTmp: true,
    })),
  ];

  const mergedFontFamilies = [
    ...sectionConfiguration.fontFamilyList,
    ...draft.configuration.fontFamilyList,
  ].reduce((unique, item) => {
    const exists = unique.find((x) => x.key === item.key);
    if (!exists) {
      unique.push(item);
    }
    return unique;
  }, []);

  draft.configuration.fontFamilyList = mergedFontFamilies;

  draft.tmp = {
    colors: mergedColorArray,
    highlightColors: mergedHighlightColorArray,
    tableStyles: mergedTableStyleArray,
    checkboxStyles: mergedCheckboxStyleArray,
    selectorStyles: mergedSelectorStyleArray,
    textStyles: mergedTextStylesArray,
  };
};

export const mergeConfigurationTableStyles = (configuration, tmp) => {
  const mergedConfiguration = [
    ...configuration.tableStyles,
    ...(tmp?.tableStyles || []),
  ];
  return mergedConfiguration;
};
export const mergeConfigurationCheckboxStyles = (configuration, tmp) => {
  const mergedConfiguration = [
    ...configuration.checkboxStyles,
    ...(tmp?.checkboxStyles || []),
  ];
  return mergedConfiguration;
};
export const mergeConfigurationSelectorStyles = (configuration, tmp) => {
  const mergedConfiguration = [
    ...configuration.selectorStyles,
    ...(tmp?.selectorStyles || []),
  ];
  return mergedConfiguration;
};
export const mergeConfigurationTextStyles = (configuration, tmp) => {
  const mergedConfiguration = [
    ...configuration.textStyles,
    ...(tmp?.textStyles || []),
  ];
  return mergedConfiguration;
};

export const mergeConfigurationColors = (configuration, tmp, useRgbaSet = true) => {
  const rgbaSet = useRgbaSet
    ? new Set(configuration.colors.map((item) => item.style))
    : null;

  const mergedArray = [
    ...configuration.colors,
    ...(tmp?.colors
      ? tmp.colors.filter((item) => (useRgbaSet ? !rgbaSet.has(item.style) : true))
      : []),
  ];
  return mergedArray;
};

export const mergeConfigurationHighlightColors = (configuration, tmp, useRgbaSet = true) => {
   const rgbaSet = useRgbaSet
    ? new Set(configuration.highlightColors.map((item) => item.style))
    : null;
  const mergedArray = [
    ...configuration.highlightColors,
    ...(tmp?.highlightColors
      ? tmp.highlightColors.filter((item) => (useRgbaSet ? !rgbaSet.has(item.style) : true))
      : []),
  ];
  return mergedArray;
};

/**
 * Remove temporary configuration belong to a linked section.
 * @param {string} params.sectionTmpId - L'identifiant temporaire de la section à supprimer.
 * @param {Object} params.draft - Le brouillon contenant les configurations temporaires.
 */
export const removeSectionTmpConfiguration = ({ sectionTmpId, draft }) => {
  if (draft.tmp) {
    const { tableStyles, checkboxStyles, selectorStyles, textStyles } =
      draft.tmp;
    draft.tmp = {
      tableStyles: tableStyles.filter(
        (item) => !sectionTmpId.includes(item.id)
      ),
      checkboxStyles: checkboxStyles.filter(
        (item) => !sectionTmpId.includes(item.id)
      ),
      selectorStyles: selectorStyles.filter(
        (item) => !sectionTmpId.includes(item.id)
      ),
      textStyles: textStyles.filter((item) => !sectionTmpId.includes(item.id)),
    };
  }
};

export const filterSameColor = (colors) => {
  const uniqueStyles = new Set();

  return colors.filter((item) => {
    if (uniqueStyles.has(item.style)) return false;

    uniqueStyles.add(item.style);
    return true;
  });
};

export const formatPagesPreview = ({ pages }) => {
  let duppedContainerId;
  let oldDuppedBreakIdx;
  return pages.map((page) => {
    const printedContainers = [...page.printedContainers];

    if (printedContainers.length === 0) {
      if (!duppedContainerId) return page;
      const duppedContainerIdToUse = duppedContainerId;
      const startFrom = oldDuppedBreakIdx;
      oldDuppedBreakIdx = undefined;
      duppedContainerId = undefined;
      return {
        ...page,
        printedContainers: [
          { id: duppedContainerIdToUse, isDupped: true, startFrom },
        ],
      };
    }

    const firstContainer = printedContainers[0];
    const hasFirstContainerDuppedIndex =
      typeof firstContainer.duppedBreakIdx !== 'undefined';
    const hasFirstContainerId = typeof firstContainer.id !== 'undefined';

    const lastContainer = printedContainers[printedContainers.length - 1];
    const hasLastContainerDuppedIndex =
      typeof lastContainer.duppedBreakIdx !== 'undefined';
    const hasLastContainerId = typeof lastContainer.id !== 'undefined';

    if (
      duppedContainerId &&
      hasFirstContainerDuppedIndex &&
      !hasFirstContainerId
    ) {
      const startFrom = oldDuppedBreakIdx;
      oldDuppedBreakIdx = firstContainer.duppedBreakIdx + oldDuppedBreakIdx;
      return {
        ...page,
        printedContainers: [
          {
            id: duppedContainerId,
            isDupped: true,
            startFrom,
            duppedBreakIdx: firstContainer.duppedBreakIdx,
          },
        ],
      };
    }

    if (duppedContainerId) {
      const startFrom = oldDuppedBreakIdx;
      printedContainers.unshift({
        id: duppedContainerId,
        isDupped: true,
        startFrom,
      });
      oldDuppedBreakIdx = undefined;
      duppedContainerId = undefined;
    }

    if (hasLastContainerDuppedIndex && hasLastContainerId) {
      duppedContainerId = lastContainer.id;
      oldDuppedBreakIdx = lastContainer.duppedBreakIdx;
    }

    return {
      ...page,
      printedContainers: printedContainers.map((container) => ({
        ...container,
      })),
    };
  });
};

export const mutateClosePreview = (draft) => {
  draft.previewRenderState = null;
  draft.pagesPreview = [];
  draft.isPreviewLoading = false;
  draft.pagesPreviewExcludedIdx = undefined;
};

import React, { createContext, useMemo } from 'react';
import { viewerTypes } from '../constants/constants';

const PreviewContext = createContext();

const usePreviewContext = () => {
  const context = React.useContext(PreviewContext);

  if (!context) {
    return { startFrom: null, duppedBreakIdx: null, fromViewer: null }
  }

  return context;
};

const PreviewProvider = ({ children, startFrom = null, duppedBreakIdx = null }) => {

  const value = useMemo(() => {
    return { startFrom, duppedBreakIdx, fromViewer: viewerTypes.PREVIEW }
  }, [startFrom, duppedBreakIdx])

  return (
    <PreviewContext.Provider value={value}>
      {children}
    </PreviewContext.Provider>
  );
};

export { PreviewProvider, usePreviewContext };

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pageWidth } from '../../constants/gridConfig'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Tooltip } from 'antd'
import { ItemTypes, viewerTypes } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useStore } from '../../store/store'

const selector =
  (box) =>
    ({
      containers
    }) => {
      const containerIdx = containers.findIndex((c) => c.columnsIds.includes(box.columnId))
      return {
        containerId: containers[containerIdx].id,
        containerIdx
      }
    };

const LineBreak = ({ box }) => {
  const { t } = useTranslation();
  const {
    containerIdx,
    containerId
  } = useStore(selector(box));
  const appendPages = useStore(({ appendPages }) => appendPages)
  const removeContainer = useStore(({ removeContainer }) => removeContainer)
  const [isHovered, setIsHovered] = useState(false);

  const fromPdf = useStore(({ fromPdf }) => fromPdf)
  if (fromPdf) return null
  return (
    <div style={{
      height: '30px',
      backgroundColor: isHovered || box.fromViewer !== viewerTypes.PREVIEW ? '#F5F5F5' : 'transparent', // Background change on hover
      color: isHovered || box.fromViewer !== viewerTypes.PREVIEW ? 'gray' : 'transparent', // Background change on hover
      width: pageWidth,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {box.fromViewer === viewerTypes.PREVIEW && isHovered && <span
        onClick={(e) => {
          e.stopPropagation();
          removeContainer(containerId, () => appendPages(containerIdx - 1))
        }}
        style={{
          position: 'absolute',
          top: 0,
          right: 10,
          cursor: 'pointer',
          color: 'red',
          fontSize: 10,
        }}
      >
        &#10060;
      </span>}
      <div> - - - - - - - - - - - - - - - - - - - - - - - - - - - - - </div>
      <div style={{ width: 10 }} />
      <Tooltip title={t(ItemTypes.LINE_BREAK)}>< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: 25 }} /></Tooltip>
      <div style={{ width: 10 }} />
      <div>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>
    </div>
  )
}

export default LineBreak

import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as documentDatasApi from '../../requests/documentDatas';
import { getImageStyle } from './imageUtils';
import ImageCascader from './ImageCascader';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

// Common
import './../common/Utils.css';
import './Image.less';

import { useStore } from '../../store/store';
import Img from './placeholder-img.png';
import { Upload, message } from 'antd';
import { convertFileToBase64 } from '../../utils/file';
import { isAnUrl } from '../../utils/browser';
import { findWithRegex } from '../../utils/dataSync';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';
import { isAImgBox, isAVariableBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { ItemTypes, MASKS_TYPE } from '../../constants/constants';
import ButtonRefresh from '../common/ButtonRefresh';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import VariableSwitcher from '../common/VariableSwitcher';
import HideInFormSwitcher from '../common/HideInFormSwitcher';
import { ContentState, Editor, EditorState } from 'draft-js';
import { decorator } from '../RichEditor/utils';
const selector =
  (id) =>
    ({
      removeBox,
      updateBox,
      configuration,
      selectedBoxId,
      currentDocument,
      currentProposal,
    }) => ({
      currentDocument,
      removeBox,
      updateBox,
      configuration,
      isSelected: selectedBoxId === id,
      currentProposal,
    });

const getNewImageBoxFromLoad = ({ oldBox, res, imgEl, column, columnWidth, header, footer }) => {
  const box = oldBox;
  box.content = { ...box.content, src: res.url, ratio: imgEl.width / imgEl.height };
  box.height = 'auto';
  if (column && !column.drawMode) {
    box.width = reinitializeBoxWidthAndHeight({
      header,
      footer,
      box,
      columnWidth,
    }).width
  }
  return box
}

const uploadFile = async ({ file, currentDocument, currentProposal, updateImage, updateBox, box, header, footer }) => {
  const img = await convertFileToBase64(file);
  const imgEl = document.createElement('img');
  let id = 0;
  if (currentDocument.type === 'template') {
    id = currentDocument.id;
  } else {
    id = currentProposal.id;
  }
  documentDatasApi.postImage(id, currentDocument.type, {
    img: /base64,(.+)/.exec(img)[1],
    filename: file.name,
  }).then((res) => {
    imgEl.onload = () => {
      if (updateImage) {
        updateImage(getNewImageBoxFromLoad({ oldBox: box, res, imgEl, header, footer }))
      }
      else {
        updateBox(box.id, (box, column, columnWidth) => {
          box = getNewImageBoxFromLoad({ oldBox: box, res, imgEl, column, columnWidth, header, footer })
        });
      }
    };
    imgEl.src = img;
  }).catch((err) => {
    if (err.response.status === 422)
      message.error({
        content: "La taille de l'image ne doit pas dépasser 1Mo",
        key: 422,
      })
  });
}

const Image = ({ box, updateImage, fromViewer }) => {
  const { currentDocument, currentProposal, isSelected } = useStore(selector(box.id));
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))

  const { updateLeftShownStatus } = useOverlay();
  const updateBox = useStore(({ updateBox }) => updateBox);
  const defaultVariablesApi = useStore(({ defaultVariablesApi }) => defaultVariablesApi)
  const [retryCount, setRetryCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isDraggingImage, setIsDraggingImage] = useState(false);
  const ref = useRef();
  const dragCounter = useRef(0);
  const { t } = useTranslation();

  const defaultVariableApiSrc = useMemo(() => {
    if (!box.content.adminValue || !defaultVariablesApi) return null

    const keys = box.content.adminValue.split('.')
    if (keys.length === 3) {
      const indexKey = keys[0]
      const originKey = Object.keys(defaultVariablesApi.opportunities_variables[indexKey])[0]
      const categoryKey = keys[1]
      const informationKey = keys[2]
      return defaultVariablesApi.opportunities_variables[indexKey][originKey][categoryKey]?.[informationKey]
    }

    const categoryKey = keys[0]
    const informationKey = keys[1]

    if (defaultVariablesApi.offer_variables) {
      return defaultVariablesApi.offer_variables[categoryKey]?.[informationKey]
    }

    return defaultVariablesApi[categoryKey]?.[informationKey]
  }, [box.content.adminValue, defaultVariablesApi])

  const getNewImageBoxFromRefresh = (oldBox) => {
    let newBox = { ...oldBox }
    if (defaultVariableApiSrc) {
      newBox.content = { ...newBox.content, src: defaultVariableApiSrc }
    } else {
      const keys = box.content.adminValue.split(".")
      const lastKey = keys[keys.length - 1]
      const beforeLastKey = keys[keys.length - 2]
      newBox.content = { ...newBox.content, src: `#{${beforeLastKey}.${lastKey}}` }
    }
    newBox.content = { ...newBox.content, ratio: null }
    newBox.height = 'auto';
    return newBox
  }

  const handleOnClickRefresh = (e) => {
    e.stopPropagation()
    if (updateImage) {
      updateImage(getNewImageBoxFromRefresh(box))
    }
    else {
      updateBox(box.id, (box) => {
        box = getNewImageBoxFromRefresh(box)
      });
    }
  }

  const src = isAnUrl(box.content.src) ? box.content.src : Img;

  const desc = useMemo(() => {
    const regex = new RegExp(/#{.*?}/, 'm');
    if (isAnUrl(box.content.src)) return null;
    if (findWithRegex(regex, box.content.src)) return t(box.content.src?.slice(2, -1))
    if (box.type === ItemTypes.IMG_VARIABLE) {
      return <Editor
        readOnly
        editorState={box.name} />
    }
    return null
  }, [box.content.src, box.type, box.name, t])

  const waitAndReload = (event) => {
    const originalSrc = event.target.src;
    if (retryCount < 3) {
      event.target.src = Img;
      setTimeout(function () {
        // console.log('retrying to load image');
        event.target.src = originalSrc;
        setRetryCount(retryCount + 1);
      }, 300);
    } else {
      event.target.src = Img;
    }
  }


  useEffect(() => {
    const handlePaste = async (event) => {
      if (!isSelected) return;
      const items = event.clipboardData.items;

      for (const item of items) {
        if (item.type.startsWith('image/')) {
          const file = item.getAsFile();
          uploadFile({
            file, currentDocument, currentProposal, updateImage, updateBox, box, header, footer
          })
        }
      }
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [box, currentDocument, currentDocument.id, currentDocument.type, currentProposal, currentProposal.id, footer, header, isSelected, updateBox, updateImage]);

  const handleDrop = (e) => {
    const file = e.dataTransfer?.files?.[0];
    if (isDraggingImage && file && file.type.startsWith('image/')) {
      e.preventDefault();
      e.stopPropagation();
      uploadFile({
        file, currentDocument, currentProposal, updateImage, updateBox, box, header, footer
      })
      setIsDraggingImage(false);
    }
  };

  const handleDragEnter = (e) => {
    // Check if at least one dragged item is an image
    const isImage = Array.from(e.dataTransfer?.items).some(item =>
      item.type.startsWith('image/')
    );

    if (isImage) {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current++;
      setIsDraggingImage(true);
    } else {
      setIsDraggingImage(false);
    }
  };

  const handleDragLeave = (e) => {
    const isImage = Array.from(e.dataTransfer?.items).some(item =>
      item.type.startsWith('image/')
    );

    if (isImage) {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current--;
      if (dragCounter.current === 0) {
        setIsDraggingImage(false);
      }
    }
  }

  return (
    <div className={`image-box ${isDraggingImage ? 'is-dragging-image' : ""}`}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onDoubleClick={(event) => {
        if (isAVariableBox(box?.type)) {
          updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
        }
        event.stopPropagation()
      }}
    >
      {currentDocument.type !== 'template' &&
        box.content.adminValue &&
        box.content.src !== defaultVariableApiSrc &&
        !fromViewer && isHovered &&
        <div style={{
          position: 'absolute',
          zIndex: 9999,
          top: 0,
          right: 0
        }}>
          <ButtonRefresh handleOnClickRefresh={handleOnClickRefresh} />
        </div>
      }
      <img
        ref={ref}
        alt='rand img'
        onError={waitAndReload}
        src={src.replace('http://', 'https://')}
        style={{
          ...getImageStyle(
            box.backgroundColor,
            box.border,
            currentDocument.type === 'template',
          ),
          width: box.width,
          height: box.height,
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '15%',
          left: '15%',
          fontWeight: 'bold',
          pointerEvents: 'none',
          wordBreak: 'break-word'
        }}
      >
        {desc}
      </div>
    </div>
  );
};

export const ImageComponent = ({ box, updateImage }) => {
  const { updateBox, currentDocument, currentProposal } = useStore(
    selector(box?.id)
  );
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const { t } = useTranslation();



  const getNewImageBoxFromAdmin = (oldBox, adminValue, srcValue) => {
    const box = oldBox;
    box.content = { ...box.content, src: srcValue, ratio: null };
    box.height = 'auto';
    box.content.adminValue = adminValue
    if (box.type === ItemTypes.IMG_VARIABLE) {
      box.name = EditorState.createWithContent(
        ContentState.createFromText(t(srcValue.slice(2, -1))),
        decorator
      )
    }
    return box
  }

  const handleUploadImg = async (fileInfos) => {
    uploadFile({
      file: fileInfos.file, currentDocument, currentProposal, updateImage, updateBox, box, header, footer
    })
  };

  return (
    <div>
      <div className='navbar__img-properties-container' style={{
        position: 'relative'
      }}>
        <HideInFormSwitcher boxId={box.id} />
        <VariableSwitcher boxId={box.id} />
        <div className='navbar__imgtopmid-properties-container'>
          <Upload
            accept="image/png, image/gif, image/jpeg"
            name='file'
            customRequest={(e) => {
              handleUploadImg(e);
            }}
            showUploadList={false}
            onMouseDown={(e) => e.preventDefault()}
          >
            <NavbarButton
              tooltipContent={t('download')}
              disabled={!box || !isAImgBox(box.type)}
              icon={< FontAwesomeIcon icon={icon({ name: 'upload', style: 'light' })} style={iconButtonStyle()} />}
            />
          </Upload>
        </div>
        <div className='navbar__imgbotmid-properties-container'>
          <ImageCascader
            onLoadImage={(srcValue, adminValue) => {
              if (updateImage) {
                updateImage(getNewImageBoxFromAdmin(box, adminValue, srcValue))
              }
              else {
                updateBox(box.id, (box, column, columnWidth) => {
                  box = getNewImageBoxFromAdmin(box, adminValue, srcValue)
                });
              }
            }}
            selectedBox={box}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Image);

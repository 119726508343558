//ANT Design
// Axios
import { instance } from './axios.instance';

import { textToRaw, formatResContent, triggersToRaw } from '../utils/dataSync';
import { fillEmptyAdminTablesBoxes } from '../utils/table';
import { mutateRemoveDeletedSectionTriggers } from '../utils/triggers';
import axios from 'axios';
import { getProposalSections } from './sections';
import { removeDuplicatesInVariableOrderArray } from '../utils/variableOrderArray';

export const postProposal = async ({
  currentDocument,
  currentProposal,
  boxes,
  masks,
  configuration,
  summaryConfiguration,
  gridConfig,
  containers,
  columns,
  variables,
  variableGroups,
  variableOrderArray,
  triggers,
}) => {
  if (!containers || !columns || !currentDocument.id) return;
  try {
    var res;
    const url =
      currentDocument.type === 'opportunity'
        ? `/proposal/${currentProposal.id}/update`
        : `/proposal/${currentProposal.id}/update`;
    // HERE ============================================
    res = await instance.post(url, {
      content: {
        boxes: textToRaw(boxes),
        configuration,
        summaryConfiguration,
        gridConfig,
        containers,
        columns,
        variables,
        variableGroups,
        variableOrderArray: removeDuplicatesInVariableOrderArray(
          variableOrderArray ?? []
        ),
        triggers: triggersToRaw(triggers, boxes),
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
    });

    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`POST proposal template  status code ${res.status}`);
  } catch (error) {
    throw error;
  }
};

export const fetchProposal = async (
  { id: proposalId, type },
  defaultVariablesApi
) => {
  try {
    let res = await instance.get(`/proposal/${proposalId}`);
    let { id, content, work, landscape, proposable_id } = res.data;
    if (res.status === 200) {
      if (id && content) {
        // Fill non fetched admin tables
        content = {
          ...content,
          boxes: await fillEmptyAdminTablesBoxes({
            boxes: content.boxes,
            proposable_id,
            type,
          }),
        };

        content = formatResContent(
          content,
          defaultVariablesApi,
          type === 'template',
          landscape
        );
      }
      const sections = await getProposalSections(id);
      content.triggers = mutateRemoveDeletedSectionTriggers({
        triggers: content.triggers,
        sections,
      });

      return {
        ...res.data,
        id,
        content,
        work,
      };
    }
    throw new Error(`GET proposal status code ${res.status}`);
  } catch (error) {
    return {
      error,
    };
  }
};

export const fetchProposals = async ({ id: proposalId, type }, variables) => {
  try {
    let res;
    if (type === 'opportunity') {
      res = await instance.get(`/opportunities/${proposalId}/proposals`);
    } else if (type === 'offer') {
      res = await instance.get(`/offers/${proposalId}/proposals`);
    }
    return res.data;
  } catch (error) {
    return {
      error,
    };
  }
};

export const postProposalAndFetchPdf = async ({
  currentDocument,
  currentProposal,
  boxes,
  masks,
  configuration,
  summaryConfiguration,
  gridConfig,
  name,
  containers,
  containerIncluded,
  columns,
  landscape,
  type,
  variables,
  variableGroups,
  variableOrderArray,
  triggers,
}) => {
  if (!containers || !columns || !currentDocument.id) return;
  try {
    const commonPayload = {
      content: {
        boxes: textToRaw(boxes),
        configuration,
        containers: containerIncluded,
        columns,
        summaryConfiguration,
        gridConfig,
        variables,
        variableGroups,
        variableOrderArray,
        triggers: triggersToRaw(triggers, boxes),
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
      landscape,
      name,
      must_be_saved: type === 'archive',
      is_for_preview: type === 'preview',
    };
    // In case you want to test with local pdf generator, fill this variable
    // with your local pdf url server in your .env (should be http://localhost:5010 normally)
    if (process.env.REACT_APP_LOCAL_PDF_URL) {
      const result = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_PDF_URL}/generate_pdf`,
          {
            ...commonPayload,
            editor_url: window.location.origin,
          },
          {
            responseType: type !== 'preview' ? 'blob' : undefined,
          }
        )
        .then((response) => {
          if (type === 'preview') return response;
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${name}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      return { ok: true, result };
    }

    const url = `/proposal/${currentProposal.id}/generate_pdf`;

    const response = postProposal({
      currentDocument,
      currentProposal,
      boxes,
      masks,
      configuration,
      summaryConfiguration,
      gridConfig,
      containers,
      columns,
      variables,
      variableGroups,
      variableOrderArray,
      triggers,
    });

    if (response) {
      const res = await instance.post(url, commonPayload);
      if (res.status === 202) {
        return res.data;
      } else {
        throw new Error(`POST proposalxz status code ${res.status}`);
      }
    }
  } catch (error) {
    throw error;
  }
};

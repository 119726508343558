import { produce } from 'immer';
import {
  mutateRemoveContainer,
  mutateAddContainers,
  mutateUpdateContainer,
  mutateRemmoveSectionContainers,
  mutateDropInContainer,
} from '../utils/containers';
import {
  computeColumnWidth,
  mutateAddColumns,
  mutateDeleteColumns,
  mutateUpdateColumn,
  newColumn,
} from '../utils/columns';
import {
  createBoxesFromPdfToImage,
  initializeBoxContent,
  isATitleBox,
  mutateAddBoxes,
  mutateSelectContainer,
  reinitializeBoxWidthAndHeight,
} from '../utils/boxes';
import { gridUnit } from '../constants/gridConfig';
import { ItemTypes, MASKS_TYPE } from '../constants/constants';

export const containerActions = (set, get) => ({
  /* CONTAINERS */
  addContainers: ({
    index,
    columnsSizes = [1],
    count = 1,
    drawMode = false,
    box,
    from = null,
    fromCopiedContainer = false,
    callback,
  }) => {
    set(
      produce((draft) => {
        mutateAddContainers({
          draft,
          index,
          count,
          columnsSizes,
          drawMode,
          box,
          from,
          fromCopiedContainer,
          shouldSelect: !draft.previewRenderState,
        });
      }),
      false,
      `add ${count} new Containers`
    );
    if (callback) callback();
  },
  removeContainer: (containerId, callback) => {
    set(
      produce((draft) => {
        mutateRemoveContainer(draft, containerId);
      }),
      false,
      `remove Container ${containerId}`
    );
    if (callback) callback();
  },
  deleteColumns: (columnsIds) => {
    set(
      produce((draft) => {
        mutateDeleteColumns(draft, columnsIds);
      }),
      false,
      `delete ${columnsIds.length} columns`
    );
  },
  setTempContainerPdfToImage: ({ id, drawMode, boxId, fileName }) => {
    set(
      produce((draft) => {
        draft.tempContainerPdfToImage = { id, drawMode };
        const container = draft.containers.find((c) => c.id === id);
        const box = draft.boxes.find((b) => b.id === boxId);
        box.fileName = fileName;
        if (
          box.type === ItemTypes.FILE_PDF_VARIABLE &&
          !!container.KeyPdfToImageParent
        ) {
          const containersToDelete = draft.containers.filter(
            (c) => c.KeyPdfToImage === container.KeyPdfToImageParent
          );
          containersToDelete.forEach((c) => {
            mutateRemoveContainer(draft, c.id);
          });
        }
      }),
      false,
      `containerPdfToImage`
    );
  },
  removePdfContainer: () => {
    set(
      produce((draft) => {
        const container = draft.containers.find(
          (c) => c.id === draft.tempContainerPdfToImage.id
        );
        const box = draft.boxes.find((b) =>
          container.columnsIds.includes(b.columnId)
        );
        if (box.type === ItemTypes.FILE_PDF_VARIABLE) {
          return;
        }
        mutateRemoveContainer(draft, draft.tempContainerPdfToImage.id);
      }),
      false,
      `removeContainerPdfToImage`
    );
  },
  addContainerPdfToImage: ({ data }) => {
    set(
      produce((draft) => {
        createBoxesFromPdfToImage({
          data,
          draft,
        });
      }),
      false,
      `Create Container from PDF`
    );
  },
  handleSelectContainer: (containerId) => {
    set(
      produce((draft) => {
        const container = draft.containers.find((c) => c.id === containerId);
        mutateSelectContainer(draft, container);
      }),
      false,
      `Select Container ${containerId}`
    );
  },
  handleUnSelectContainer: (from = 'anonymous') => {
    if (get().selectedContainer) {
      set(
        produce((draft) => {
          // draft.eventManagerEnabled = false;
          draft.selectedContainer = null;
          // draft.boxIsMovable = false;
          // draft.groupSelection = [];
        }),
        false,
        `unselectContainer from ${from}`
      );
    }
  },
  removeSectionContainers: (fromBoxId, { sectionId, dateTimestamp }) => {
    set(
      produce((draft) => {
        mutateRemmoveSectionContainers(draft, fromBoxId, {
          sectionId,
          dateTimestamp,
        });
      }),
      false,
      `remove Section Containers ${fromBoxId}`
    );
  },
  updateContainer: ({ id, fn }) => {
    set(
      produce((draft) => {
        const container = draft.containers.find((c) => c.id === id);
        fn(container);
        mutateUpdateContainer(draft, container);
      }),
      false,
      `update Container ${id}`
    );
  },
  updateColumnsInContainer: ({
    columnsSizes = [],
    index,
    containerId,
    newBoxes = [],
  }) => {
    set(
      produce((draft) => {
        const container = draft.containers.find((c) => c.id === containerId);
        const newColsIndex = index === null ? 0 : index + 1;
        const oldColsLength = container.columnsIds.length;
        const newColsLength = columnsSizes.length;
        const colLenghtDiff = newColsLength - oldColsLength;
        const newColSizes = columnsSizes.filter(
          (_, idx) => idx >= newColsIndex && idx < colLenghtDiff + newColsIndex
        );
        const oldColSizes = columnsSizes.filter(
          (_, idx) =>
            !(idx >= newColsIndex && idx < colLenghtDiff + newColsIndex)
        );
        const newColumns = newColSizes.map((size) => newColumn({ size }));
        mutateAddColumns(draft, newColumns);
        const boxesToAdd = [];
        newColumns.forEach((c) => {
          newBoxes.forEach((b) => {
            let boxToAdd = initializeBoxContent({
              box: b,
              configuration: draft.configuration,
              titles: draft.boxes.filter((box) => isATitleBox(box.type)),
              column: c,
              parentId: c.id,
            });
            boxToAdd = reinitializeBoxWidthAndHeight({
              header: draft.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
              footer: draft.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
              box: boxToAdd,
              columnWidth: computeColumnWidth({
                landscape: draft.landscape,
                marginLeft: draft.configuration.margins.left * gridUnit,
                marginRight: draft.configuration.margins.right * gridUnit,
                containerColumnsLength: newColsLength,
                size: c.size,
                columnGap: draft.configuration.columnGap,
              }),
            });
            boxesToAdd.push(boxToAdd);
          });
        });
        container.columnsIds.forEach((cId, idx) => {
          const column = draft.columns.find((c) => c.id === cId);
          const newSize = oldColSizes[idx];
          let boxIdx = draft.boxes.findIndex((b) => b.columnId === cId);
          mutateUpdateColumn(draft, { ...column, size: newSize });
          if (boxIdx !== -1) {
            draft.boxes[boxIdx] = reinitializeBoxWidthAndHeight({
              header: draft.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
              footer: draft.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
              box: draft.boxes[boxIdx],
              columnWidth: computeColumnWidth({
                landscape: draft.landscape,
                marginLeft: draft.configuration.margins.left * gridUnit,
                marginRight: draft.configuration.margins.right * gridUnit,
                containerColumnsLength: newColsLength,
                size: newSize,
                columnGap: draft.configuration.columnGap,
              }),
            });
          }
        });
        container.columnsIds.splice(
          newColsIndex,
          0,
          ...newColumns.map((c) => c.id)
        );
        mutateAddBoxes({
          draft,
          newBoxes: boxesToAdd,
          drawMode: false,
        });

        mutateDropInContainer({ draft, container, box: boxesToAdd[0] });
      }),
      false,
      `add ${columnsSizes.length} in container ${containerId}`
    );
  },
  setDisplayMargins: () => {
    set(
      produce((draft) => {
        draft.displayMargins = !draft.displayMargins;
      }),
      false,
      'setDisplayMargins'
    );
  },
});

import React, { useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, notification } from 'antd';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import AdderMenu from './AdderMenu';
import { useOutsideAlerter, useZIndexContainer } from '../../hooks';
import { containerBackground, pageBackground, roundButtonWidth } from '../../constants/constants';
import { useStore } from '../../store/store';
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

const selector = ({ landscape,
  addContainers, configuration, containers, unCollapseTitles, boxes, deleteColumns, updateContainer, selectedContainer,
  updateColumnsInContainer, selectedBoxId, handleUnSelectBox }) => ({
    containers,
    landscape,
    addContainers,
    lineSpacing: configuration.lineSpacing,
    unCollapseTitles,
    boxes,
    deleteColumns,
    updateContainer,
    selectedContainer,
    updateColumnsInContainer,
    selectedBoxId,
    handleUnSelectBox
  })

const AdderColumns = ({
  containerIndex = null,
  withoutContainers = false,
  columnId,
  columnIndex = null,
  boxIsOver,
  canDrop,
  disabled = false,
  defaultFontSizeIcon,
  fromLinkedSection,
  titleCollapsed,
  fromViewer
}) => {

  const { landscape, lineSpacing, selectedContainer, selectedBoxId, boxes, handleUnSelectBox } = useStore(selector);

  const containerId = useStore(({ containers }) => {
    if (columnId || containerIndex >= containers.length) {
      const containerIndexFromCol = containers.findIndex((c) => c.columnsIds.includes(columnId));
      if (containerIndexFromCol >= containers.length) return null;
      return containers[containerIndexFromCol]?.id
    }
    if (withoutContainers) return containers[0]?.id;
    return containers[containerIndex]?.id
  })
  const [isHovered, setIsHovered] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const unlockProposal = useStore(({ configuration }) => configuration.unlockProposal)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  useOutsideAlerter(wrapperRef, setMenuIsOpen);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message) => {
    api.warning({
      message: 'Ajout impossible',
      description: message,
      placement: 'top',
    });
  };


  const closeMenu = useCallback(() => {
    setMenuIsOpen(false)
  }, []);

  const canEdit = isTemplate || unlockProposal

  const show = canEdit && ((boxIsOver && canDrop) || (!boxIsOver && (isHovered || menuIsOpen || withoutContainers)));

  const hasPriority = !!containerId && menuIsOpen && !disabled

  useZIndexContainer(containerId, hasPriority)

  const onClick = () => {
    if (titleCollapsed) {
      openNotification(<div>Vous ne pouvez pas ajouter d'élément dans un <b>titre non déplié</b></div>)
      return
    }
    if (fromLinkedSection) {
      openNotification(<div>Vous ne pouvez pas ajouter d'élément dans une <b>section liée</b></div>)
      return
    }

    if (!canEdit) return;
    if (!landscape) {
      setMenuIsOpen(!menuIsOpen)
    } else {
      setMenuIsOpen(!menuIsOpen)
      // addContainers({ index: containerIndex, drawMode: true });
    }
  }
  const colorAdderCol = () => {
    if (disabled && show) {
      return '#fcc3bc'
    } else if (show) {
      return '#4096ff63'
    } else {
      return 'transparent'
    }
  }

  const width = columnId ? roundButtonWidth : lineSpacing;

  return (
    <div
      ref={wrapperRef}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: columnId ? roundButtonWidth : (landscape ? pageHeight : pageWidth),
        height: columnId ? "100%" : width,
      }}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: show ? 1 : undefined,
          flexDirection: columnId ? "column" : "row",
          width: columnId ? roundButtonWidth : "100%",
          height: columnId ? "100%" : width,
        }}>
        <div
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClick}
          data-role={pageBackground}
          data-parent={containerBackground}
          style={{
            backgroundColor: colorAdderCol(),
            display: 'flex',
            alignItems: "center",
            transition: "ease-in-out 0.2s",
            cursor: canEdit ? "pointer" : 'default',
            flexDirection: columnId ? "column" : "row",
            width: columnId ? 20 : "100%",
            height: columnId ? "100%" : width,
          }}>
          <div style={{
            backgroundColor: show ? '#4096ff3d' : 'transparent',
            display: 'flex',
            justifyContent: "center",
            position: "relative",
            flexDirection: columnId ? "column" : "row",
            width: columnId ? 1 : "100%",
            height: columnId ? "100%" : 1,
          }}>
            {show && !columnId && <Button shape="circle" size={14}
              style={{
                width: 30,
                height: 30,
                minWidth: 30,
                minHeight: 30,
                display: "flex",
                justifyContent: "center",
                top: 0,
                alignItems: "center",
                position: "absolute",
                transform: columnId ? "translateX(calc(-50% + 0.5px))" : "translateY(calc(-50% + 0.5px)",
              }}
              icon={< FontAwesomeIcon icon={icon({ name: 'plus', style: 'light' })}
                style={{ margin: 0, fontSize: defaultFontSizeIcon ?? 20 }} />}
            />}
            {(selectedContainer?.columnsIds?.includes(columnId) || boxes?.find(b => b.id === selectedBoxId)?.columnId === columnId) && columnId &&
              <Button
                icon={<FontAwesomeIcon icon={faPlus}
                  style={{
                    transition: "color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
                    color: isHovered ? "white" : "rgba(0, 0, 0, 0.88)",
                  }} />}
                onClick={() => handleUnSelectBox()}
                style={{
                  zIndex: 1000,
                  position: "absolute",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 20,
                  height: 20,
                  top: -35,
                  padding: 0,
                  backgroundColor: isHovered ? "#388bff" : undefined,
                  borderColor: isHovered ? "white" : undefined,
                  transform: "translateX(-50%)",
                }}
              />
            }

          </div>
        </div>
      </div>
      {!disabled && menuIsOpen && <AdderMenu
        columnId={columnId}
        containerIndex={containerIndex}
        closeMenu={closeMenu}
        columnIndex={columnIndex}
        fromViewer={fromViewer}
      />}
    </div>
  )
}

export default AdderColumns

import { TitlesTypes, ItemTypes } from '../constants/constants';
import _ from 'lodash';
import { isATitleBox } from './boxes';
import {
  calculateHeight,
  calculateLeftPadding,
  calculateWidth,
} from '../constants/gridConfig';
import { sortVariableOrderArray } from './variableOrderArray';

// CONSTANTS
// =============================================================================
export const headerIndentation = {
  [ItemTypes.TITLE]: 1,
  [ItemTypes.TITLE_2]: 2,
  [ItemTypes.TITLE_3]: 3,
  [ItemTypes.TITLE_4]: 4,
};
export const letterMaj = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const letterMin = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];
export const letterBlank = [''];
export const romanNumber = [
  '',
  'C',
  'CC',
  'CCC',
  'CD',
  'D',
  'DC',
  'DCC',
  'DCCC',
  'CM',
  '',
  'X',
  'XX',
  'XXX',
  'XL',
  'L',
  'LX',
  'LXX',
  'LXXX',
  'XC',
  '',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
];
// =============================================================================

// FUNCTIONS
// =============================================================================

export const romanize = (num) => {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(''),
    roman = '',
    i = 3;
  while (i--) roman = (romanNumber[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
};

export const getTitlesConfig = (config) => {
  const titlesConfig = _.pick(config, Object.values(TitlesTypes));
  const titlesDisplayTypes = Object.values(TitlesTypes).map((t) => {
    return titlesConfig[t].typeTitle;
  });
  return titlesDisplayTypes;
};

// for data sync when indexTitle is still string like "1.2.1"
export const createIndexArray = (indexTitleStr) => {
  return indexTitleStr.match(/\d+/g);
};

export const displayTitleIndex = (
  indexTitleArray,
  separator,
  titlesTypeConfig,
  hideTitleIndex
) => {
  const indexesToDisplay = transcriptTitle(
    indexTitleArray,
    titlesTypeConfig,
    hideTitleIndex
  ).filter((_i, level) => !(hideTitleIndex && level === 0));
  if (indexesToDisplay.length > 0) {
    return indexesToDisplay.join(separator) + separator;
  } else {
    return '';
  }
};

export const transcriptTitle = (indexCounter, titlesTypeConfig) => {
  return indexCounter.map((counter, index) => {
    const titleTypeConfig = titlesTypeConfig[index];
    if (titleTypeConfig === 'letterMin') {
      return letterMin[counter - 1];
    } else if (titleTypeConfig === 'letterMaj') {
      return letterMaj[counter - 1];
    } else if (titleTypeConfig === 'romain') {
      return romanize(counter);
    } else if (titleTypeConfig === 'letterBlank') {
      return letterMaj[0];
    } else {
      return counter;
    }
  });
};

const formatArrayToDecimal = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return 0.0; // Retourne 0.0 si le tableau est indéfini ou vide
  }

  // Si le tableau contient un seul élément, on le retourne comme un float
  if (arr.length === 1) {
    return parseFloat(arr[0]);
  }

  // Séparer le premier élément (avant le point) et les autres (après le point)
  const [first, ...rest] = arr;

  // Construire la chaîne avec le premier nombre et les autres collés ensemble après le point
  const decimalString = `${first}.${rest.join('')}`;
  return parseFloat(decimalString); // Convertir en float
};

const getTitleLevelIndex = (boxType) => {
  // starts at 0 for "TITLE" ("title1")
  return Number(boxType[boxType.length - 1]) - 1;
};

const sortArrayOrderVariable = (draft, boxesToMove) => {
  if (
    boxesToMove.some(
      (box) =>
        box.type === ItemTypes.TEXT_VARIABLE ||
        box.type === ItemTypes.CHECKBOXS_VARIABLE ||
        box.type === ItemTypes.CUSTOM_TEXT_VARIABLE ||
        box.type === ItemTypes.VARIABLE_GROUP ||
        box.type === ItemTypes.SECTION_VARIABLE ||
        box.type === ItemTypes.SELECTOR_VARIABLE ||
        box.type === ItemTypes.IMG_VARIABLE ||
        box.type === ItemTypes.TABLE_VARIABLE ||
        box.type === ItemTypes.FILE_PDF_VARIABLE
    )
  ) {
    sortVariableOrderArray(draft);
  }
};

const allBoxesOutsideDrawContainer = (
  draft,
  {
    endContainerIndexEnd,
    drawModeLogicLength,
    startContainerIndex,
    sectionMovedLogic,
  }
) => {
  const boxes = draft.boxes;
  const containersArray = draft.containers.slice(
    startContainerIndex + drawModeLogicLength,
    endContainerIndexEnd - sectionMovedLogic
  );
  const allColumnIdsToDelete = containersArray.flatMap(
    (container) => container.columnsIds
  );
  return boxes.filter((box) => allColumnIdsToDelete.includes(box.columnId));
};

const findLastTitleinDrawContainer = (draft, { oldDragTitle, titleType }) => {
  return draft.boxes.find((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(oldDragTitle.content.indexTitle)
    ) {
      if (titleType[box.type] > titleType[oldDragTitle.type]) return true;
      // if(dropPosition === 0) return true
      else return box.type === oldDragTitle.type;
    } else return false;
  });
};

const moveBoxesInSameDrawContainer = (
  draft,
  {
    oldDragTitle,
    aboveDropTitle,
    dropPosition,
    boxesInColumn,
    startContainerIndex,
    titleType,
    sectionMovedLogic,
  }
) => {
  const boxes = draft.boxes;
  const containers = [...draft.containers];

  const initBoxesLength = boxes.length;
  const initContainersLength = draft.containers.length;
  const firstTitleBoxIndex = boxes.findIndex((box) => isATitleBox(box.type));
  const firstTitleBox = boxes.find((box) => isATitleBox(box.type));
  const firstContainerWithTitleInsideIndex = draft.containers.findIndex(
    (container) =>
      container.columnsIds.includes(firstTitleBox.columnId.toString())
  );

  const firstBoxId = boxesInColumn[0].id;
  const lastBoxId = boxesInColumn[boxesInColumn.length - 1].id;
  const containerRef = containers.find((container) =>
    container.columnsIds.includes(boxesInColumn[0].columnId)
  );

  const firstBoxIndex = boxes.findIndex((box) => box.id === firstBoxId);
  const lastBoxIndex = boxes.findIndex((box) => box.id === lastBoxId);
  const containerIndex = containers.findIndex(
    (container) => container.id === containerRef.id
  );

  const findNextTitleBox = function (draft, params) {
    var lastTitleInDraw = findLastTitleinDrawContainer(draft, params);

    if (!lastTitleInDraw) return undefined;

    if (
      lastTitleInDraw.columnId.toString() !==
      params.oldDragTitle.columnId.toString()
    ) {
      return lastTitleInDraw;
    }

    return findNextTitleBox(draft, {
      oldDragTitle: lastTitleInDraw,
      titleType: params.titleType,
    });
  };

  const calculateEndContainerIndex = function (
    draft,
    nextTitleBox,
    startContainerIndex
  ) {
    var containerIndex = draft.containers.findIndex(function (container) {
      return container.columnsIds.includes(
        nextTitleBox ? nextTitleBox.columnId : ''
      );
    });

    var endIndex =
      containerIndex === -1 ? draft.containers.length : containerIndex;

    return endIndex === startContainerIndex + 1
      ? startContainerIndex
      : endIndex;
  };

  // Calculate the end container index and drawModeLogicLength
  var nextTitleBox = findNextTitleBox(draft, {
    oldDragTitle: oldDragTitle,
    titleType: titleType,
  });
  var endContainerIndexEnd = calculateEndContainerIndex(
    draft,
    nextTitleBox,
    startContainerIndex
  );
  let drawModeLogicLength = 0;
  if (draft.columns.find((c) => c.id === oldDragTitle.columnId).drawMode) {
    if (endContainerIndexEnd - startContainerIndex - 1 > 0) {
      //If there is no more boxes in the container we delete the container
      const checkIfBoxInsideContainer = draft.boxes.find(
        (box) => box.columnId === oldDragTitle.columnId
      );
      if (checkIfBoxInsideContainer) {
        drawModeLogicLength = 1;
      } else {
        drawModeLogicLength = 0;
      }
    } else if (endContainerIndexEnd - startContainerIndex === 0) {
      drawModeLogicLength = 0;
    }
  }

  // Get boxes under the draw container ref
  const boxesUnderTitle = allBoxesOutsideDrawContainer(draft, {
    endContainerIndexEnd,
    drawModeLogicLength,
    startContainerIndex,
    sectionMovedLogic,
  });

  // Récupérer les containers à déplacer
  const containersUnderTitle = [];
  for (
    let i = startContainerIndex + drawModeLogicLength;
    i < endContainerIndexEnd - sectionMovedLogic;
    i++
  ) {
    containersUnderTitle.push(draft.containers[i]);
  }
  // Delete boxes and containers from their old position after the draw container ref
  const containersArrayToDelete = draft.containers.slice(
    startContainerIndex + drawModeLogicLength,
    endContainerIndexEnd - sectionMovedLogic
  );
  const allColumnIdsToDelete = containersArrayToDelete.flatMap(
    (container) => container.columnsIds
  );
  draft.boxes = draft.boxes.filter(
    (box) => !allColumnIdsToDelete.includes(box.columnId)
  );
  draft.containers.splice(
    startContainerIndex + drawModeLogicLength,
    endContainerIndexEnd -
      startContainerIndex -
      (drawModeLogicLength + sectionMovedLogic)
  );

  // delete boxes and containers from their old position
  draft.boxes.splice(firstBoxIndex, lastBoxIndex - firstBoxIndex + 1);
  draft.containers.splice(containerIndex, 1);

  //Delete hidden box and container linked section
  if (sectionMovedLogic === 1) {
    draft.boxes.splice(firstBoxIndex - sectionMovedLogic, 1);
    draft.containers.splice(containerIndex - sectionMovedLogic, 1);
  }

  //-----------------------------------------------------------------------------------
  // find the new position
  const nextBoxSameLevel = boxes.find((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(aboveDropTitle.node.indexTitle)
    ) {
      if (dropPosition === 0) return true;
      else return box.type === aboveDropTitle.node.type;
    } else return false;
  });

  const newContainerInsertIndex = draft.containers.findIndex((container) =>
    container.columnsIds.includes(nextBoxSameLevel?.columnId)
  );

  const nextBoxIndex = draft.boxes.findIndex((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(aboveDropTitle.node.indexTitle)
    ) {
      if (dropPosition === 0) return true;
      else return box.type === aboveDropTitle.node.type;
    } else return false;
  });

  const nextBoxIndexEnd = nextBoxIndex === -1 ? initBoxesLength : nextBoxIndex;
  const nextContainerIndex =
    newContainerInsertIndex === -1
      ? initContainersLength
      : newContainerInsertIndex;

  /* Logic if a linked section is moved */
  let linkedSectionBox = null;
  let linkedSectionContainer = null;
  if (sectionMovedLogic === 1) {
    // get the linked section box just before nextBoxIndexEnd
    linkedSectionBox = boxes[firstBoxIndex - sectionMovedLogic];
    // get the linked section container just before nextContainerIndex
    linkedSectionContainer = containers[containerIndex - sectionMovedLogic];
  }

  /* Boxes and container to inject to the new position */
  const boxesToMove = [
    ...(linkedSectionBox ? [linkedSectionBox] : []),
    ...boxesInColumn,
    ...boxesUnderTitle,
  ];

  const containersToMove = [
    ...(linkedSectionContainer ? [linkedSectionContainer] : []),
    containerRef,
    ...containersUnderTitle,
  ];

  /* Insert boxes and containers to the new position */
  draft.containers.splice(
    dropPosition === -1
      ? firstContainerWithTitleInsideIndex
      : nextContainerIndex,
    0,
    ...containersToMove
  );
  draft.boxes.splice(
    dropPosition === -1 ? firstTitleBoxIndex : nextBoxIndexEnd,
    0,
    ...boxesToMove
  );

  sortArrayOrderVariable(draft, boxesInColumn);
};

const nextBoxIndex = ({
  boxes,
  titleNewBox,
  dropPosition,
  initBoxesLength,
  forDelete = false,
  isLastChildren = false,
}) => {
  if (forDelete) return initBoxesLength;
  const nextBoxIndex = boxes.findIndex((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(titleNewBox.content.indexTitle)
    ) {
      if (dropPosition === 0) return true;
      if (isLastChildren) return true;
      else return box.type === titleNewBox.type;
    } else return false;
  });

  const nextBoxIndexEnd = nextBoxIndex === -1 ? initBoxesLength : nextBoxIndex;
  return nextBoxIndexEnd;
};

const deleteBoxesByPositionUnderTitleDelete = ({
  boxes,
  columnId,
  titleBox,
  draft,
}) => {
  // Ensure we have a valid title box to compare positions
  if (!titleBox || !isATitleBox(titleBox.type)) return boxes;

  // Filter boxes to exclude those under the title box in the same column
  let numberOfBoxesDeleted = 0;
  const boxesUnderTitleToDelete = boxes.filter(
    (b, idx) =>
      b.columnId === columnId &&
      b.top >= titleBox.top &&
      formatArrayToDecimal(b?.content?.indexTitle) <
        formatArrayToDecimal(titleBox.content.indexTitle) &&
      b.id !== titleBox.id
  );
  const boxesUnderTitleToDeleteIds = boxesUnderTitleToDelete.map((b) => b.id);
  draft.boxes = draft.boxes.filter(
    (b) => !boxesUnderTitleToDeleteIds.includes(b.id) && b.id !== titleBox.id
  );
  return numberOfBoxesDeleted;
};

export const mutateMoveBoxesIndex = (
  draft,
  {
    oldDragTitle,
    aboveDropTitle,
    dropPosition,
    boxesInColumn,
    forDelete = false,
  }
) => {
  // if((aboveDropTitle.node.from_linked_section || oldDragTitle.from_linked_section) && dropPosition !== -1){debugger; return};
  if (
    oldDragTitle?.maskId &&
    aboveDropTitle?.node?.maskId === oldDragTitle?.maskId
  ) {
    return;
  }

  // if(oldDragTitle?.from_linked_section) {
  //   // debugger;
  //   return "Déplacement impossible dans une section liée";
  // }

  const boxes = draft.boxes;
  const boxesToMove = [];
  const containersToMove = [];
  const initBoxesLength = boxes.length;
  const initContainersLength = draft.containers.length;

  const firstTitleBoxIndex = boxes.findIndex((box) => isATitleBox(box.type));
  const firstTitleBox = boxes.find((box) => isATitleBox(box.type));

  const firstContainerWithTitleInsideIndex = draft.containers.findIndex(
    (container) =>
      container.columnsIds.includes(firstTitleBox.columnId.toString())
  );

  const isLastChildren = aboveDropTitle?.node?.isLast;

  // Trouver la nouvelle box et son container associé
  const titleNewBox = boxes.find((box) => box.id === aboveDropTitle?.node?.id);

  const nextBoxIndexEnd = nextBoxIndex({
    boxes,
    titleNewBox,
    dropPosition,
    initBoxesLength,
    forDelete: forDelete,
  });

  // Avoid inserting a title inside linked section or inside a draw container
  if (!forDelete) {
    if (
      boxes[nextBoxIndexEnd - 1]?.from_linked_section &&
      boxes[nextBoxIndexEnd]?.from_linked_section &&
      boxes[nextBoxIndexEnd - 1]?.fromSectionId ===
        boxes[nextBoxIndexEnd]?.fromSectionId
    ) {
      return 'Déplacement impossible dans une section liée';
    }

    const col1 = draft.columns.find(
      (c) => c.id === boxes[nextBoxIndexEnd]?.columnId
    );
    let previousTitle = null;
    for (let i = nextBoxIndexEnd - 1; i >= 0; i--) {
      const currentBox = boxes[i];
      if (isATitleBox(currentBox.type)) {
        previousTitle = currentBox;
        break; // Arrête dès qu'un titre est trouvé
      }
    }
    const col2 = draft.columns.find((c) => c.id === previousTitle?.columnId);
    if (col1?.id === col2?.id && col1.drawMode) {
      return 'Déplacement impossible dans une ligne de dessin';
    }
  }

  // Trouver le container du TitleBox
  const startContainerIndex = draft.containers.findIndex((container) =>
    container.columnsIds.includes(oldDragTitle.columnId)
  );

  const titleType = {
    [ItemTypes.TITLE]: 4,
    [ItemTypes.TITLE_2]: 3,
    [ItemTypes.TITLE_3]: 2,
    [ItemTypes.TITLE_4]: 1,
  };

  // Trouver le prochain titre de même type, ou la fin du tableau si aucun n'est trouvé
  const nextTitleBox = draft.boxes.find((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(oldDragTitle.content.indexTitle)
    ) {
      if (titleType[box.type] > titleType[oldDragTitle.type]) return true;
      // if(dropPosition === 0) return true
      else return box.type === oldDragTitle.type;
    } else return false;
  });

  const endContainerIndex = draft.containers.findIndex((container) =>
    container.columnsIds.includes(nextTitleBox?.columnId)
  );

  const endContainerIndexEnd =
    endContainerIndex === -1 ? draft.containers.length : endContainerIndex;

  // Indices des boxes à déplacer
  const startBoxIndex = boxes.findIndex((box) => box.id === oldDragTitle.id);
  const endBoxIndex = draft.boxes.findIndex((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(oldDragTitle.content.indexTitle)
    ) {
      if (titleType[box.type] > titleType[oldDragTitle.type]) return true;
      // if(dropPosition === 0) return true
      else return box.type === oldDragTitle.type;
    } else return false;
  });

  const moveEndIndex = endBoxIndex === -1 ? boxes.length : endBoxIndex; // Si aucun titre n'est trouvé, déplacer jusqu'à la fin

  // Check if section linked is breaked
  if (
    boxes[moveEndIndex - 1]?.from_linked_section &&
    boxes[moveEndIndex]?.from_linked_section &&
    boxes[moveEndIndex - 1]?.fromSectionId ===
      boxes[moveEndIndex]?.fromSectionId
  ) {
    return 'Section liée brisée par le déplacement';
  }

  let sectionMovedLogic = 0;
  // Check if the content moved is a section
  if (
    boxes[startBoxIndex]?.from_linked_section &&
    boxes[moveEndIndex]?.from_linked_section &&
    boxes[startBoxIndex]?.fromSectionId ===
      boxes[moveEndIndex - 2]?.fromSectionId
  ) {
    sectionMovedLogic = 1;
  }

  // Récupérer les boxes à déplacer
  for (
    let i = startBoxIndex - sectionMovedLogic;
    i < moveEndIndex - sectionMovedLogic;
    i++
  ) {
    boxesToMove.push(boxes[i]);
  }

  // Récupérer les containers à déplacer
  for (
    let i = startContainerIndex - sectionMovedLogic;
    i < endContainerIndexEnd - sectionMovedLogic;
    i++
  ) {
    containersToMove.push(draft.containers[i]);
  }

  let drawModeLogicLength = 0;
  //if forDelete in title in drawMode container we delete all boxes under the title and not the title
  if (
    forDelete &&
    draft.columns.find((c) => c.id === oldDragTitle.columnId).drawMode
  ) {
    deleteBoxesByPositionUnderTitleDelete({
      boxes,
      columnId: oldDragTitle.columnId,
      titleBox: oldDragTitle,
      draft,
    });
    if (endContainerIndexEnd - startContainerIndex - 1 > 0) {
      //If there is no more boxes in the container we delete the container
      const checkIfBoxInsideContainer = draft.boxes.find(
        (box) => box.columnId === oldDragTitle.columnId
      );
      if (checkIfBoxInsideContainer) {
        drawModeLogicLength = 1;
      } else {
        drawModeLogicLength = 0;
      }
    } else if (endContainerIndexEnd - startContainerIndex === 0) {
      return;
    }
  }

  // If the title is in a draw container
  if (boxesInColumn?.length > 0 && !forDelete) {
    moveBoxesInSameDrawContainer(draft, {
      oldDragTitle,
      aboveDropTitle,
      dropPosition,
      boxesInColumn,
      startContainerIndex,
      titleType,
      sectionMovedLogic,
    });
    return;
  }

  // Si aucune box n'est déplacée
  if (
    dropPosition !== -1 &&
    nextBoxIndex({
      boxes: draft.boxes,
      titleNewBox,
      dropPosition,
      initBoxesLength,
      forDelete: forDelete,
      isLastChildren,
    }) === startBoxIndex
  ) {
    return false;
  }

  // Retirer les containers et boxes à l'ancienne position
  const containersArrayToDelete = draft.containers.slice(
    startContainerIndex + drawModeLogicLength - sectionMovedLogic,
    endContainerIndexEnd - sectionMovedLogic
  );
  const allColumnIdsToDelete = containersArrayToDelete.flatMap(
    (container) => container.columnsIds
  );
  draft.boxes = draft.boxes.filter(
    (box) => !allColumnIdsToDelete.includes(box.columnId)
  );
  draft.containers.splice(
    startContainerIndex + drawModeLogicLength - sectionMovedLogic,
    endContainerIndexEnd - (startContainerIndex + drawModeLogicLength)
  );

  // Stop here if is for delete a title and his content
  if (forDelete) {
    return;
  }

  //-----------------ADD BOXES AND CONTANIER IN THEIR NEW POSITIONS------------------

  const nextBoxSameLevel = boxes.find((box) => {
    if (
      isATitleBox(box.type) &&
      formatArrayToDecimal(box.content.indexTitle) >
        formatArrayToDecimal(titleNewBox.content.indexTitle)
    ) {
      if (dropPosition === 0) return true;
      if (isLastChildren) return true;
      else return box.type === titleNewBox.type;
    } else return false;
  });

  const newContainerInsertIndex = draft.containers.findIndex((container) =>
    container.columnsIds.includes(nextBoxSameLevel?.columnId)
  );

  const nextContainerIndex =
    newContainerInsertIndex === -1
      ? initContainersLength
      : newContainerInsertIndex;

  let indexBoxToInsert = nextBoxIndex({
    boxes: draft.boxes,
    titleNewBox,
    dropPosition,
    initBoxesLength,
    forDelete: forDelete,
    isLastChildren,
  });

  draft.containers.splice(
    dropPosition === -1
      ? firstContainerWithTitleInsideIndex
      : nextContainerIndex,
    0,
    ...containersToMove
  );

  draft.boxes.splice(
    dropPosition === -1 ? firstTitleBoxIndex : indexBoxToInsert,
    0,
    ...boxesToMove
  );

  sortArrayOrderVariable(draft, boxesToMove);
};

export const selectLevelTitle = (
  titleId,
  {
    defaultConfiguration,
    selectedBox,
    boxes,
    boxId,
    updateBox,
    updateIndexTitle,
  }
) => {
  const titleIndices = {
    [ItemTypes.TITLE]: 0,
    [ItemTypes.TITLE_2]: 1,
    [ItemTypes.TITLE_3]: 2,
    [ItemTypes.TITLE_4]: 3,
  };
  const titleType = {
    0: ItemTypes.TITLE,
    1: ItemTypes.TITLE_2,
    2: ItemTypes.TITLE_3,
    3: ItemTypes.TITLE_4,
  };
  let indexPositionTitle = titleIndices[titleId] ?? -1;

  const width = calculateWidth(
    defaultConfiguration.margins,
    defaultConfiguration.indentationTitles,
    indexPositionTitle
  );
  const left = calculateLeftPadding(
    defaultConfiguration.margins,
    defaultConfiguration.indentationTitles,
    indexPositionTitle
  );
  const valueIncrementation =
    titleIndices[selectedBox.type] > indexPositionTitle
      ? -1
      : titleIndices[titleId];

  boxes.forEach((boxChild) => {
    if (!isATitleBox(boxChild.type)) return;
    if (boxId === boxChild.id) return;

    const content = boxChild.content.indexTitle.slice(
      0,
      selectedBox.content.indexTitle.length
    );

    if (selectedBox.content.indexTitle.join(',') === content.join(',')) {
      const newLevelType =
        titleType?.[titleIndices[boxChild.type] + valueIncrementation] ||
        ItemTypes.TITLE_4;
      const titleLevelIndexFactor =
        titleIndices[
          titleType?.[titleIndices[boxChild.type] + valueIncrementation] ||
            ItemTypes.TITLE_4
        ];
      const widthChildTitle = calculateWidth(
        defaultConfiguration.margins,
        defaultConfiguration.indentationTitles,
        titleLevelIndexFactor
      );
      const leftChildTittle = calculateLeftPadding(
        defaultConfiguration.margins,
        defaultConfiguration.indentationTitles,
        titleLevelIndexFactor
      );
      updateBox(boxChild.id, (box) => {
        box.content.indexTitle = [1, 0, 1, 1];
        box.type = newLevelType;
        box.clientWidth = widthChildTitle;
        box.clientHeight = calculateHeight();
        box.left = leftChildTittle;
        box.width = widthChildTitle;
        box.height = calculateHeight();
      });
    }
  });

  updateBox(boxId, (box) => {
    box.type = titleId;
    box.clientWidth = width;
    box.clientHeight = calculateHeight();
    box.left = left;
    box.width = width;
    box.height = calculateHeight();
  });

  updateIndexTitle();
};

export const mutateTitlesIndexes = (draft, maskId = null) => {
  // titlesTypeConfig is an array of each level type
  // ex: ['roman', '', 'letterMaj', 'letterMin']
  let indexCounter = [0, 0, 0, 0];
  let typeTitle = ['title1', 'title2', 'title3', 'title4'];
  const mask = draft.masks.find((m) => m.id === maskId);
  let boxesToUpdate = maskId === null ? draft.boxes : mask.boxes;
  // return produce(boxes, (draftBoxes) => {
  boxesToUpdate = boxesToUpdate.map((box, index, boxes) => {
    if (!isATitleBox(box.type)) {
      return box;
    }

    const boxLevel = getTitleLevelIndex(box.type);
    if (boxLevel + 1 < indexCounter.length) {
      const indexesToReset = _.range(boxLevel + 1, indexCounter.length);
      indexesToReset.forEach((indexValue) => {
        indexCounter[indexValue] = 0;
      });
    }
    indexCounter[boxLevel] += 1;
    const indexTitle = indexCounter.slice(0, boxLevel + 1);
    return {
      ...box,
      type: typeTitle[boxLevel],
      content: {
        ...box.content,
        indexTitle,
      },
    };
  });
  if (maskId === null) {
    draft.boxes = boxesToUpdate;
  } else {
    mask.boxes = boxesToUpdate;
  }
};

// export const resizeSummary = (nextBoxes, summaryConfig) => {
//   let totalHeight = 0;
//   nextBoxes.forEach((box) => {
//     if (
//       // If it's a title (type included in ["title1", "title2"...])
//       Object.values(TitlesTypes).includes(box.type) &&
//       // And it's visible
//       summaryConfig[box.type].visibility === 'visible'
//     ) {
//       totalHeight += box.height;
//     }
//   });

//   return nextBoxes.map((box) => {
//     if (box.type === ItemTypes.SUMMARY) {
//       return {
//         // same as previous box
//         ...box,
//         // BUT the height is new
//         height: totalHeight,
//       };
//     } else {
//       return box;
//     }
//   });
// };

export const mutateResizeSummary = (draft, maskId = null) => {
  const mask = draft.masks.find((m) => m.id === maskId);
  const boxesToUpdate = maskId === null ? draft.boxes : mask.boxes;
  const summaryConfig = draft.summaryConfiguration;
  let totalHeight = 0;
  boxesToUpdate.forEach((box) => {
    if (
      // If it's a title (type included in ["title1", "title2"...])
      Object.values(TitlesTypes).includes(box.type) &&
      // And it's visible
      summaryConfig[box.type].visibility === 'visible'
    ) {
      totalHeight += box.height;
    }
  });
  // ICI voir si pas de problème avec la fonctionnalité d'affichage
  // de certaines lignes seulement dans certains sommaires
  boxesToUpdate
    .filter((box) => box.type === ItemTypes.SUMMARY)
    .map((b) => {
      return {
        // same as previous box
        ...b,
        // BUT the height is new
        height: totalHeight,
      };
    });
};

export const indentTitles = ({ box, columns, margins, indentationTitles }) => {
  const column = columns.find((c) => c.id === box.columnId);

  if (!column || column.drawMode || !isATitleBox(box.type)) return box;

  return {
    ...box,
    width: calculateWidth(
      margins,
      indentationTitles,
      box.content.indexTitle.length - 1
    ),
  };
};

import { Col, Row, Tabs } from 'antd';
import { TitlesTypes } from '../../../constants/constants';
import { useStore } from '../../../store/store';
import TitleConfiguration from '../../common/TitleConfiguration';
import { getTitleStyle } from '../../Title/titleUtils';
import { handleTitleIndex } from './utils';
import { useCallback, useMemo } from 'react';

const selector = ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  gridConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
}) => ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  gridConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
});



export const TabsSummary = () => {
  const {
    configuration,
    setConfiguration,
    gridConfiguration,
    summaryConfiguration,
    setSummaryConfiguration,
  } = useStore(selector);

  const getSummaryTitleFamily = (titleType) => {
    return summaryConfiguration[titleType].fontFamily !== '' ? summaryConfiguration[titleType].fontFamily
      : configuration[titleType].fontFamily !== '' ? configuration[titleType].fontFamily
        : configuration.fontFamily
  }

  const styleRuleChangeObject = useCallback((options = {}) => {
    return {
      setFunction: options.summary ? setSummaryConfiguration : setConfiguration,
      configObject: options.summary ? summaryConfiguration : configuration,
    };
  }, [configuration, setConfiguration, setSummaryConfiguration, summaryConfiguration]);

  const handleSummarySelectVisibility = useCallback((type) => (e) => {
    const titles = Object.values(TitlesTypes);
    if (type === titles[0]) {
      return;
    }
    const visibilityValue = e.target.checked ? 'visible' : '';
    const titleIndex = titles.findIndex((value) => value === type);
    const titlesToSet = e.target.checked
      ? // Si cochée, on affiche tous les précédents
      titles.slice(0, titleIndex + 1)
      : // Si décochée on dés-affiche tous les suivants
      titles.slice(titleIndex, titles.length);
    // On affecte les titres à changer
    const newTitles = {};
    titlesToSet.forEach((title) => {
      newTitles[title] = {
        ...summaryConfiguration[title],
        visibility: visibilityValue,
      };
    });
    setSummaryConfiguration({
      ...newTitles,
    });
  }, [setSummaryConfiguration, summaryConfiguration]);

  const handleSelectColor =
    useCallback((type, options = {}) =>
      (selectedColor) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            color: configuration.colors.find(
              (color) => color.label === selectedColor
            ),
          },
        });
      }, [configuration?.colors, styleRuleChangeObject]);

  const handleSelectStyle =
    useCallback((type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            fontStyle: e.target.checked ? 'italic' : '',
          },
        });
      }, [styleRuleChangeObject]);

  const handleSelectWeight =
    useCallback((type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            fontWeight: e.target.checked ? 'bold' : '',
          },
        });
      }, [styleRuleChangeObject]);

  const handleSelectDecoration =
    useCallback((type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            textDecoration: e.target.checked ? 'underline' : '',
          },
        });
      }, [styleRuleChangeObject]);

  const handleChangeFontSize =
    useCallback((type, options = {}) =>
      (value) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: { ...change.configObject[type], fontSize: value },
        });
      }, [styleRuleChangeObject]);

  const handleChangeMarginTop =
    useCallback((type, options = {}) =>
      (value) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: { ...change.configObject[type], marginTop: value },
        });
      }, [styleRuleChangeObject]);

  const handleChangeMarginBottom =
    useCallback((type, options = {}) =>
      (value) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: { ...change.configObject[type], marginBottom: value },
        });
      }, [styleRuleChangeObject]);

  const tabsItems = useMemo(() => {
    return Object.values(TitlesTypes).map((titleType, index) => {
      return {
        label: `Titres de niveau ${titleType[5]}`,
        key: titleType,
        children: <Col span={24} key={`title-${titleType}`}>
          <TitleConfiguration
            key={`summary-${titleType}`}
            title={titleType}
            level={titleType[5]}
            styleRules={summaryConfiguration[titleType]}
            colors={summaryConfiguration.colors}
            onChangeVisibility={handleSummarySelectVisibility(titleType)}
            onChangeColor={handleSelectColor(titleType, {
              summary: true,
            })}
            onChangeFontStyle={handleSelectStyle(titleType, {
              summary: true,
            })}
            onChangeWeight={handleSelectWeight(titleType, {
              summary: true,
            })}
            onChangeTextDecoration={handleSelectDecoration(titleType, {
              summary: true,
            })}
            onChangeFontSize={handleChangeFontSize(titleType, {
              summary: true,
            })}
            onChangeMarginTop={handleChangeMarginTop(titleType, {
              summary: true,
            })}
            onChangeMarginBottom={handleChangeMarginBottom(titleType, {
              summary: true,
            })}
            onChangeBackgroundColor={null}
            onChangeBorderColor={null}
            onChangeBorderPlace={null}
            configuration={configuration}
            titleType={titleType}
            type='summary'
            summaryConfiguration={summaryConfiguration[titleType]}
          />
        </Col>,
      }
    }
    )
  }, [
    configuration,
    handleChangeFontSize,
    handleSelectColor,
    handleSelectDecoration,
    handleSelectStyle,
    handleSelectWeight,
    handleSummarySelectVisibility,
    summaryConfiguration
  ])

  return (
    <div>
      {Object.values(TitlesTypes).map((titleType) => {
        return (
          <div
            key={titleType}
            style={{
              marginTop: '0.5%',
              visibility: summaryConfiguration[titleType].visibility === 'visible' ? 'visible' : 'hidden',
            }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: "space-between",
              paddingLeft: `${configuration.indentationTitles * (titleType[5] - 1)}px`,
              fontFamily: getSummaryTitleFamily(titleType)
            }}>
              <span
                style={getTitleStyle(
                  summaryConfiguration[titleType].backgroundColor,
                  summaryConfiguration[titleType].borders,
                  summaryConfiguration[titleType],
                  summaryConfiguration[titleType].color.style,
                  summaryConfiguration[titleType].rest
                )}
              >
                <span style={{
                  paddingRight: gridConfiguration[titleType].paddingTitle
                }}>
                  {handleTitleIndex(titleType)}
                </span>
                <span >{'Sommaire Titre de niveau ' + titleType[5]}</span>
              </span>
              <span
                style={{
                  fontFamily: getSummaryTitleFamily(titleType),
                  fontSize: summaryConfiguration[titleType].fontSize,
                  color: summaryConfiguration[titleType].color.style,
                }}>n°page</span>
            </div>
          </div>
        )
      })}
      <Row gutter={[0, 24]} style={{ marginTop: '2%' }}>
        <Tabs
          style={{ width: "100%" }}
          items={tabsItems}
          destroyInactiveTabPane
        />
      </Row>
    </div>
  );
};

import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import { iconButtonStyle, navbarButtonStyle } from '../../utils/styles';
import { NavbarListButton } from './NavbarListButton';
import { defaultZoom, dynamicZoom, ItemTypes, variablesIcons } from '../../constants/constants';
import { initializeGroupBoxes, isASelectionBox } from '../../utils/boxes';
import { NavbarButton } from './NavbarButton';
import { Separator } from './Navbar';

const selector = (state) => {
  const boxes = [
    ...state.boxes,
    ...state.masks.map((m) => m.boxes).flat(),
  ]
  const box = boxes.find((b) => b.id === state.selectedBoxId)
  return {
    alignBoxDefault: state.alignBoxDefault,
    alignBoxesDrawMode: state.alignBoxesDrawMode,
    alignBoxAbsolutDrawMode: state.alignBoxAbsolutDrawMode,
    groupSelectionBoxes: state.groupSelection.length === 0 ? [] : boxes.filter((b) => state.groupSelection.includes(b.id)),
    box,
    drawMode: box && (!box.columnId || state.columns.find((col) => col.id === box.columnId).drawMode),
    isSelectionBox: isASelectionBox(box?.type),
    addGroupBox: state.addGroupBox,
    ungroupBoxes: state.ungroupBoxes,
    configuration: state.configuration,
    distributeBoxesDrawMode: state.distributeBoxesDrawMode
  }
};
export const AlignmentComponent = () => {
  const { distributeBoxesDrawMode, alignBoxesDrawMode, alignBoxAbsolutDrawMode, groupSelectionBoxes, box, drawMode, alignBoxDefault, isSelectionBox, addGroupBox, ungroupBoxes, configuration } = useStore(selector);
  const { t } = useTranslation();
  const iconFontSize = 22
  const columnAlignButtons = {
    alignLeft: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-left', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', right: '5px' }} />
      </span >,
      positions: { horizontal: "start" },
    },
    alignRight: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-right', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', left: '5px' }} />
      </span >,
      positions: { horizontal: "end" },
    },
    alignTop: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-up', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', top: '-4px' }} />
      </span >,
      positions: { vertical: "start" },
    },
    alignBottom: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-down', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', bottom: '-4px' }} />
      </span >,
      positions: { vertical: "end" },
    },
    alignCenterHorizontal: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'border-center-v', family: 'duotone', style: 'solid' })} style={{ "--fa-primary-opacity": "0.5", "--fa-secondary-opacity": "0", fontSize: iconFontSize - 4 }} />
      </span >,
      positions: { horizontal: "center" },
    },
    alignCenterVertical: {
      icon: < span className="fa-layers" style={{ display: 'flex', width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'border-center-h', family: 'duotone', style: 'solid' })} style={{ "--fa-primary-opacity": "0.5", "--fa-secondary-opacity": "0", fontSize: iconFontSize - 4 }} />
      </span >,
      positions: { vertical: "center" },
    },

    // alignTopLeft: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-top-left', style: 'light' })} style={iconButtonStyle()} />,
    //   positions: { vertical: "start", horizontal: "start" },
    // },
    // alignTopRight: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-top-left', style: 'light' })} style={{ ...iconButtonStyle(), transform: 'rotate(90deg)' }} />,
    //   positions: { vertical: "start", horizontal: "end" },
    // },
    // alignBottomLeft: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-bottom-right', style: 'light' })} style={{ ...iconButtonStyle(), transform: 'rotate(90deg)' }} />,
    //   positions: { vertical: "end", horizontal: "start" },
    // },
    // alignBottomRight: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-bottom-right', style: 'light' })} style={iconButtonStyle()} />,
    //   positions: { vertical: "end", horizontal: "end" },
    // },

    // alignCenter: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-inner', style: 'light' })} style={iconButtonStyle()} />,
    //   positions: { vertical: "center", horizontal: "center" },
    // },
  };

  const relativeAlignButtons = {
    alignLeft: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-left', style: 'light' })} style={iconButtonStyle()} />,
      side: 'left',
      type: 'align'
    },
    alignRight: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-right', style: 'light' })} style={iconButtonStyle()} />,
      side: 'right',
      type: 'align'
    },
    alignTop: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-top', style: 'light' })} style={iconButtonStyle()} />,
      side: 'top',
      type: 'align'
    },
    alignBottom: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-bottom', style: 'light' })} style={iconButtonStyle()} />,
      side: 'bottom',
      type: 'align'
    },
    alignVertical: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-center-vertical', style: 'light' })} style={iconButtonStyle()} />,
      side: 'vertical',
      type: 'align'
    },
    alignHorizontal: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-center-horizontal', style: 'light' })} style={iconButtonStyle()} />,
      side: 'horizontal',
      type: 'align'
    },
    distributeVertical: {
      icon: < FontAwesomeIcon icon={icon({ name: 'distribute-spacing-vertical', style: 'light' })} style={iconButtonStyle()} />,
      side: 'vertical',
      type: 'distribute'
    },
    distributeHorizontal: {
      icon: < FontAwesomeIcon icon={icon({ name: 'distribute-spacing-horizontal', style: 'light' })} style={iconButtonStyle()} />,
      side: 'horizontal',
      type: 'distribute'
    },
  };

  const absoluteAlignButtons = {
    alignToMarginLeft: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-left-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'left',
    },
    alignToMarginRight: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-right-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'right',
    },
    alignToMarginTop: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-up-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'top',
    },
    alignToMarginBottom: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-down-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'bottom',
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarListButton
        key="AlignmentList"
        title={t('alignment')}
        disabled={!box || box.type === ItemTypes.LINE_BREAK || isSelectionBox}
        hidden={!box || box.type === ItemTypes.LINE_BREAK || isSelectionBox}
        icon={< FontAwesomeIcon icon={icon({ name: 'objects-align-left', style: 'light' })} style={iconButtonStyle()} />}
      >
        {drawMode && groupSelectionBoxes.length === 0 &&
          Object.keys(absoluteAlignButtons).map((buttonName) => {
            return (
              <Tooltip title={t(buttonName)} placement='right' mouseLeaveDelay='0'>
                <Button
                  key={`alignment${buttonName}`}
                  style={{
                    ...navbarButtonStyle,
                    marginBottom: '1px',
                  }}
                  disabled={false}
                  icon={absoluteAlignButtons[buttonName].icon}
                  onMouseDown={() => alignBoxAbsolutDrawMode(absoluteAlignButtons[buttonName].side)}
                />
              </Tooltip>
            );
          })}
        {drawMode && groupSelectionBoxes.length !== 0 &&
          Object.keys(relativeAlignButtons).map((buttonName) => {
            return (
              <Tooltip title={t(buttonName)} placement='right' mouseLeaveDelay='0'>
                <Button
                  key={`alignment${buttonName}`}
                  style={{
                    ...navbarButtonStyle,
                    marginBottom: '1px',
                  }}
                  disabled={false}
                  icon={relativeAlignButtons[buttonName].icon}
                  onMouseDown={() => relativeAlignButtons[buttonName].type === 'align' ?
                    alignBoxesDrawMode(relativeAlignButtons[buttonName].side) :
                    distributeBoxesDrawMode(relativeAlignButtons[buttonName].side)}
                />
              </Tooltip>
            );
          })}
        {!drawMode &&
          Object.keys(columnAlignButtons).map((buttonName) => {
            return (
              <Tooltip title={t(buttonName)} placement='right' mouseLeaveDelay='0'>
                <Button
                  key={`alignment${buttonName}`}
                  style={{
                    ...navbarButtonStyle,
                    marginBottom: '1px',
                  }}
                  disabled={false}
                  icon={columnAlignButtons[buttonName].icon}
                  onMouseDown={() => alignBoxDefault(columnAlignButtons[buttonName].positions)}
                />
              </Tooltip>
            );
          })}
      </NavbarListButton>
      <NavbarButton
        tooltipContent={box?.type === ItemTypes.GROUP_BOXES ? t('ungroup') : t('group')}
        onClick={
          () => {
            if (box?.type === ItemTypes.GROUP_BOXES) {
              ungroupBoxes(box)
              return
            }
            const groupBoxes = [...groupSelectionBoxes, box]
            const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;
            const newGroupBox = initializeGroupBoxes(groupBoxes, zoomFactor)
            addGroupBox(newGroupBox)
          }
        }
        hidden={box?.type === ItemTypes.GROUP_BOXES ? false :
          (groupSelectionBoxes.length === 0 || groupSelectionBoxes.some((b) => b.type === ItemTypes.GROUP_BOXES))}
        icon={
          box?.type === ItemTypes.GROUP_BOXES ? <FontAwesomeIcon icon={icon({ name: 'link-slash', style: 'light' })} style={iconButtonStyle()} /> :
            <FontAwesomeIcon icon={variablesIcons[ItemTypes.GROUP_BOXES]} style={iconButtonStyle()} />}
      />
      <Separator hidden={box?.type === ItemTypes.GROUP_BOXES ? false :
        (groupSelectionBoxes.length === 0 || groupSelectionBoxes.some((b) => b.type === ItemTypes.GROUP_BOXES))} />
    </div>
  );
};

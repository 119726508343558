import React, { useCallback, useEffect, useRef, useState } from 'react'
import { gridUnit, masksDefaultHeight, pageHeight, pageWidth } from '../../constants/gridConfig'
import { useStore } from '../../store/store';
import './Container.less'
import { borderColumnColor, containerBackground, ItemTypes, minContainerHeight, pageBackground, roundButtonWidth, viewerTypes } from '../../constants/constants';
import DeleteContainerButton from './DeleteContainerButton';
import RearangeColumnsButton from './RearangeColumnsButton';
import { useOutsideAlerter } from '../../hooks';
import ColumnWrapper from '../Column/ColumnWrapper';
import EditableContainerButton from './EditableContainerButton';

const selector = (id, fromViewer) => ({
  configuration,
  containers,
  columns,
  maskEditing,
  masks,
  boxes,
  landscape,
  fromPdf,
  selectedContainer,
  handleSelectContainer,
  boxVariableSelectedId
}) => {
  const container = containers.find((c) => c.id === id);
  const mask = !container && (fromViewer || fromPdf || (maskEditing.length > 0
    && maskEditing.includes(id)))
    && masks.find((m) => m.id === id);
  let height;
  if (mask) {
    if (masksDefaultHeight.hasOwnProperty(mask.id)) {
      height = mask.height ?? masksDefaultHeight[mask.id]
    } else {
      height = landscape ? pageWidth : pageHeight
    }
  }
  else height = container.height;
  const drawMode = mask || columns.some((c) => container.columnsIds.includes(c.id) && c.drawMode)
  return {
    height,
    mask,
    box: boxes.find((b) => container?.columnsIds.includes(b.columnId)),
    columnsIds: container?.columnsIds || [],
    marginLeft: configuration.margins.left * gridUnit,
    marginRight: configuration.margins.right * gridUnit,
    drawMode,
    hasBox: !mask && boxes.some((b) => container.columnsIds.includes(b.columnId)) &&
      !(!drawMode && boxes.some((b) => container.columnsIds.includes(b.columnId) && b.hideField)),
    landscape,
    fontFamily: configuration.fontFamily,
    fontSize: configuration.fontSize,
    selectedContainer,
    handleSelectContainer,
    container: container,
    fromPdf,
    boxVariableSelectedId,
    columnGap: configuration.columnGap
  }
};

const Container = React.memo(({ id, fromViewer }) => {
  const {
    columnsIds,
    marginLeft,
    marginRight,
    drawMode,
    height,
    mask,
    hasBox,
    landscape,
    fontFamily,
    fontSize,
    selectedContainer,
    handleSelectContainer,
    container,
    fromPdf,
    boxVariableSelectedId,
    columnGap,
    box
  } = useStore(selector(id, fromViewer));
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [containerSelected, setContainerSelected] = useState(false);
  const [isResizingContainer, setIsResizingContainer] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setMenuIsOpen);

  const renderContent = () => {
    if (mask) return <ColumnWrapper
      id={mask.id}
      isContainerHovered={false}
      setIsResizingContainer={setIsResizingContainer}
      fromViewer={fromViewer}
    />
    return columnsIds.map((colId, index) => (
      <ColumnWrapper
        data-parent={containerBackground}
        id={colId}
        key={colId}
        isContainerHovered={isHovered}
        containerColumnsLength={columnsIds.length}
        setIsResizingContainer={setIsResizingContainer}
        fromViewer={fromViewer}
        isLastColumn={index === columnsIds.length - 1}
        isFirstColumn={index === 0}
        isMiddleColumn={index > 0 && index < columnsIds.length - 1}
      />
    ))
  }

  const stylecontainerSelected = useCallback(() => {
    if (fromPdf || fromViewer) {
      return {}
    }

    if (container?.sectionId && selectedContainer?.sectionId) {
      if (selectedContainer?.id === id) {
        return {
          outline: `2px #d7d9db ${drawMode ? "dotted" : "dashed"}`
        }
      }
      if ((container.fromBoxId && container.fromBoxId === selectedContainer.fromBoxId) || (container.sectionId === selectedContainer.sectionId && container.dateTimestamp === selectedContainer.dateTimestamp)) {
        return {
          outline: `2px #fce8d4 ${drawMode ? "dotted" : "dashed"}`
        }
      }
    }
    if (drawMode && selectedContainer?.id !== id) {
      return {
        outline: `2px ${borderColumnColor} dotted`
      }
    }
    if (selectedContainer?.id === id || drawMode) {
      return {
        outline: `2px #d7d9db ${drawMode ? "dotted" : "dashed"}`
      }
    }
  }, [selectedContainer, id, container, drawMode, fromPdf, fromViewer])

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setContainerSelected(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const hasContainerOptions = (menuIsOpen || (!mask && containerSelected)) && !boxVariableSelectedId

  const containerExtraWidth = hasContainerOptions ? roundButtonWidth + 16 : 0

  return <div id={id}
    className='container'
    ref={containerRef}
    onMouseOver={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={() => { fromViewer !== viewerTypes.PREVIEW && setContainerSelected(!containerSelected) }}
    data-role={pageBackground}
    data-parent={containerBackground}
    style={{
      width: (landscape ? pageHeight : pageWidth) + containerExtraWidth,
      minHeight: hasBox || isResizingContainer ? "auto" : minContainerHeight,
      height: isResizingContainer ? "auto" : height,
      position: 'relative',
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
      zIndex: selectedContainer?.id === id ? 2 : 0,
    }}>
    <div
      data-role={pageBackground}
      data-parent={containerBackground}
      onClick={() => { fromViewer !== viewerTypes.PREVIEW && handleSelectContainer(id) }}
      style={{
        width: (landscape ? pageHeight : pageWidth),
        minHeight: "inherit",
        height: "100%",
        display: "flex",
        paddingLeft: (drawMode || box?.type === ItemTypes.LINE_BREAK) ? 0 : marginLeft,
        paddingRight: (drawMode || box?.type === ItemTypes.LINE_BREAK) ? 0 : marginRight,
        columnGap: columnGap,
        ...(stylecontainerSelected())
      }}>
      {renderContent()}
    </div>
    {hasContainerOptions &&
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        right: 0,
        gap: 8
      }}
        ref={wrapperRef}>
        <RearangeColumnsButton id={id} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        <DeleteContainerButton id={id} />
        <EditableContainerButton id={id} />
      </div>
    }
  </div>
})

export default Container

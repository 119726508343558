import React, { useMemo, useRef } from 'react'
import { getMarginBackground, gridUnit, masksDefaultHeight, masksPosition } from '../../constants/gridConfig'
import { MASKS_TYPE, borderColumnColor, borderColumnErrorColor, containerBackground, maxHeaderFooterHeight, minContainerHeight, pageBackground, viewerTypes } from '../../constants/constants';
import BoxWrapper from '../common/BoxWrapper';
import { Resizable } from 're-resizable';
import { useStore } from '../../store/store';
import { maxContainerHeight } from '../../utils/containers';
import { reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { computeColumnWidth } from '../../utils/columns';

const selector = (id, mask, columnMaskId, linkedMaskHasNotPageNumber) => ({
  containers,
  updateContainer,
  displayMargins,
  landscape,
  boxVariableSelectedId,
  fromPdf,
  updateMask,
  masks
}) => {
  let containerHeight;
  if (mask) {
    if (mask.id === MASKS_TYPE.HEADER.id) {
      containerHeight = mask.height ?? masksDefaultHeight.header
    } else if (mask.id === MASKS_TYPE.FOOTER.id) {
      containerHeight = mask.height ?? masksDefaultHeight.footer
    }
  } else {
    containerHeight = containers.find((c) => c.columnsIds.includes(id))?.height
  }
  let hasNotPageNumber;
  if (!mask && columnMaskId) {
    hasNotPageNumber = linkedMaskHasNotPageNumber || !!containers.find((c) => c.columnsIds.includes(id))?.KeyPdfToImage || masks.find((m) => m.id === columnMaskId)?.hasNotPageNumber
  }
  return {
    containerId: mask ? undefined : containers.find((c) => c.columnsIds.includes(id))?.id,
    hasNotPageNumber,
    containerHeight,
    updateContainer,
    displayMargins,
    landscape,
    isHeaderOrFooterMask: mask && (id === MASKS_TYPE.HEADER || id === MASKS_TYPE.FOOTER),
    boxVariableSelectedId,
    fromPdf,
    updateMask
  }
};

const Column = ({
  id,
  drawMode,
  canDrop,
  isDragging,
  columnWidth,
  configuration,
  boxes,
  mask,
  isResizable,
  setIsResizingContainer,
  backgroundColor = 'transparent',
  fromViewer,
  columnMaskId,
  linkedMaskHasNotPageNumber
}) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const {
    updateContainer,
    containerId,
    containerHeight,
    displayMargins,
    landscape,
    isHeaderOrFooterMask,
    fromPdf,
    updateMask,
    hasNotPageNumber
  } = useStore(selector(id, mask, columnMaskId, linkedMaskHasNotPageNumber));
  const resizableRef = useRef(null);


  const columnInnerStyle = useMemo(() => {
    if (!drawMode) return {}
    const marginsDisplay = getMarginBackground({
      display: displayMargins,
      configuration,
      landscape,
      showVerticalBorder: !isResizable && !isHeaderOrFooterMask
    });

    const baseStyle = {
      ...(fromPdf || fromViewer === viewerTypes.DMZ || fromViewer === viewerTypes.PREVIEW ? {} : { outline: `2px ${canDrop || !isDragging ? borderColumnColor : borderColumnErrorColor} dotted` }),
      overflow: 'hidden',
      position: 'absolute',
      width: `100%`,
      maxWidth: `100%`,
      height: "100%",
      color: configuration.baseFontColor?.style,
      transition: 'outline 200ms ease',
      backgroundColor,
      ...marginsDisplay
    }

    if (!mask) return baseStyle
    return { ...baseStyle, ...masksPosition(mask.id, header, footer), }
  }, [
    drawMode,
    displayMargins,
    configuration,
    landscape,
    canDrop,
    isDragging,
    backgroundColor,
    mask,
    isResizable,
    isHeaderOrFooterMask,
    fromPdf,
    header,
    footer,
    fromViewer
  ]);

  const onResizeStop = (...args) => {
    setIsResizingContainer(false);
    if (mask) {
      updateMask({
        id: mask.id, fn: (mask, draft) => {
          if (mask.id === footer.id) {
            mask.boxes.forEach((_, idx) => {
              const box = mask.boxes[idx];
              box.top += Math.floor(args[2].clientHeight) - (mask.height ?? MASKS_TYPE.FOOTER.height);
            })
          }

          mask.height = Math.floor(args[2].clientHeight);
          draft.columns.forEach((column) => {
            const container = draft.containers.find((c) => c.columnsIds.includes(column.id))
            const newHeader = mask.id === header.id ? mask : header
            const newFooter = mask.id === footer.id ? mask : footer


            if (!column.drawMode) {
              draft.boxes = draft.boxes.map((box) => {
                if (box.columnId === column.id) {
                  return reinitializeBoxWidthAndHeight({
                    box,
                    columnWidth: computeColumnWidth({
                      landscape: draft.landscape,
                      drawMode: column.drawMode,
                      marginLeft: draft.configuration.margins.left * gridUnit,
                      marginRight: draft.configuration.margins.right * gridUnit,
                      size: column.size,
                      containerColumnsLength: container.columnsIds.length,
                    }),
                    header: newHeader,
                    footer: newFooter,
                  })
                }
                return box
              })
            } else if (!column.isOldPage && !column.maskId) {
              if (container.height > maxContainerHeight({ header: newHeader, footer: newFooter })) {
                container.height = maxContainerHeight({ header: newHeader, footer: newFooter })
              }
            }
          })
        }
      })
    }
    else {
      updateContainer({
        id: containerId, fn: (container) => {
          container.height = Math.floor(args[2].clientHeight)
        }
      })
    }
  }

  const maxHeight = () => {
    if (mask && (mask.id === header.id || mask.id === footer.id)) {
      return maxHeaderFooterHeight
    }
    return maxContainerHeight({ header, footer })
  }

  const renderBoxes = () =>
    <div
      id={id}
      style={columnInnerStyle}
      data-role={pageBackground}
      data-parent={containerBackground}
      className={`${drawMode ? 'drawMode' : ''} ${fromPdf && hasNotPageNumber ? 'has-not-page-number' : ''}`}
    >
      {boxes.map(({ id: boxId }) => (
        <BoxWrapper
          id={boxId}
          key={boxId}
          drawMode={drawMode}
          columnWidth={columnWidth}
          fromViewer={fromViewer}
        />
      ))}
    </div>

  return isResizable ? <Resizable
    ref={resizableRef}
    enable={{
      top: mask && mask.id === footer.id,
      right: false,
      bottom: !mask || mask.id === header.id,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false
    }}
    onResizeStart={() => {
      resizableRef.current.updateSize({
        width: "100%",
        height: containerHeight
      });
      setIsResizingContainer(true)
    }}
    onResizeStop={onResizeStop}
    maxHeight={maxHeight()}
    minHeight={minContainerHeight}
    size={{
      width: "100%"
    }}
  >
    {renderBoxes()}
  </Resizable> : renderBoxes()
}

export default Column

import { Button, Col, Dropdown, Row, Tooltip } from 'antd';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { uuid } from '../../../constants/constants';
import { useStore } from '../../../store/store';
import ColorSelector from '../../common/ColorSelector';
import ColorButton from '../../TextStylingComponent/ColorButton';
import { RemoveColor } from './ConfigurationModal';
import './colorTab.less';
import { actionButtonBottomMargin, filterSameColor, mergeConfigurationColors, mergeConfigurationHighlightColors } from '../../../utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const selector = ({
  configuration,
  setConfiguration,
  updateColor,
  updateHighlightColors,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
  tmp
}) => ({
  configuration,
  setConfiguration,
  updateColor,
  updateHighlightColors,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
  tmp
});

const isAdminSelector = (state) => ({
  isAdmin: state?.user?.isAdmin,
  user: state?.user,
});

export const RemovableColorButton = ({ color, onColorClick, canRemove, onRemove }) => {
  const items = [
    {
      key: 1,
      danger: true,
      label:
        <RemoveColor
          colorSelect={canRemove ? color : null}
          onclick={canRemove ? onRemove : null}
        />
    }
  ]
  return (
    <Dropdown
      menu={{ items }}
      style={{
        padding: '0px',
        backgroundColor: 'transparent',
      }}
    >
      <a href="/" onClick={(e) => e.preventDefault()}>
        <ColorButton
          onToggle={onColorClick}
          active={false}
          label={color.label}
          colorStyle={color.style}
          size='32px'
        />
      </a>
    </Dropdown >
  )
}

export const TabsColors = () => {
  const {
    configuration,
    setConfiguration,
    updateColor,
    updateHighlightColors,
    summaryConfiguration,
    setSummaryConfiguration,
    tmp
  } = useStore(selector);
  const { isAdmin } = useStore(isAdminSelector);
  const [currentColor, setCurrentColor] = useState('');
  const [modifyColorIndex, setModifyColorIndex] = useState('');
  const [isEditingColor, setIsEditingColor] = useState(false);
  const { t } = useTranslation();

  const handleModifyColor = () => {
    if (isEditingColor === 'templateColor') {
      updateColor(modifyColorIndex, currentColor);
    } else if (isEditingColor === 'highlightColors') {
      updateHighlightColors(modifyColorIndex, currentColor);
    }
    setIsEditingColor(false);
  };

  const handleRemoveColor = (label) => {
    if (label === 'white' || label === 'black' || label === 'transparent')
      return;
    const colors = [...configuration.colors];
    const index = colors.findIndex((c) => c.label === label);
    if (index !== -1) {
      colors.splice(index, 1);
    }
    setConfiguration({ colors });
    setSummaryConfiguration({ colors });
  };
  const handleRemoveHighlightColor = (label) => {
    if (
      label === 'white' ||
      label === 'black' ||
      label === 'transparent' ||
      label === 'yellow'
    )
      return;
    const colors = [...mergeConfigurationHighlightColors(configuration, tmp)];
    const index = colors.findIndex((c) => c.label === label);
    if (index !== -1) {
      colors.splice(index, 1);
    }
    setConfiguration({ ...configuration, highlightColors: colors });
  };

  const handleChangeShapeColor = (selectedColor) => {
    setConfiguration({
      ...configuration,
      shapeColor: configuration.colors.find(
        (color) => color.label === selectedColor
      ),
    });
  };

  const handleOnChange = ({ rgb: { r, g, b, a } }) => {
    const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
    setCurrentColor(rgba);
  };

  const informationTooltip = (content) => <Tooltip
    trigger="hover"
    placement='bottom'
    mouseLeaveDelay='0'
    title={content}>
    <FontAwesomeIcon
      icon={icon({ name: 'circle-info', style: 'solid' })}
      style={{ marginLeft: 5, color: 'black' }}
    />
  </Tooltip>

  return (
    <>
      <Row style={{ marginTop: '2rem' }} justify="space-around">
        <Col span={12}>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: actionButtonBottomMargin }}>{t('default-shape-and-line-color')} :</div>
            <ColorSelector
              forcedValue={configuration.shapeColor.label}
              onChange={handleChangeShapeColor}
              colors={configuration.colors}
            />
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: actionButtonBottomMargin }}>Couleurs du Template {informationTooltip("Couleurs disponible dans le template")} :</div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {configuration.colors.filter((c) => c.label !== 'transparent').map((color, index) => (
                <RemovableColorButton
                  key={color.label}
                  color={color}
                  onColorClick={() => {
                    setCurrentColor(color.style);
                    setModifyColorIndex(index);
                    setIsEditingColor('templateColor');
                  }}
                  canRemove
                  onRemove={() => handleRemoveColor(color.label)}
                />
              ))}
            </div>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: actionButtonBottomMargin }}>Couleurs du surligneur
              {informationTooltip("Couleurs du surligneur disponible dans le template")} :</div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {configuration.highlightColors.filter((c) => c.label !== 'transparent').map((color, index) => (
                <RemovableColorButton
                  key={color.label}
                  color={color}
                  onColorClick={() => {
                    setCurrentColor(color.style);
                    setModifyColorIndex(index);
                    setIsEditingColor('highlightColor');
                  }}
                  canRemove
                  onRemove={() => handleRemoveHighlightColor(color.label)}
                />
              ))}
            </div>
          </div>
          {tmp?.colors && <div style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: actionButtonBottomMargin }}>Couleurs provenant de sections liées
              {informationTooltip("Couleurs non disponible dans le template")} :</div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {filterSameColor(tmp?.colors).filter((c) => c.label !== 'transparent').map((color, index) => (
                <RemovableColorButton
                  key={color.label}
                  color={color}
                  onColorClick={() => {
                    setCurrentColor(color.style);
                    setModifyColorIndex(index);
                    setIsEditingColor('templateColor');
                  }}
                  canRemove
                  onRemove={() => handleRemoveColor(color.label)}
                />
              ))}
            </div>
          </div>}
          {tmp?.highlightColors && <div style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: actionButtonBottomMargin }}>Couleurs du surligneur provenant de sections liées
              {informationTooltip("Couleurs du surligneur non disponible dans le template")} :</div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {filterSameColor(tmp?.highlightColors).filter((c) => c.label !== 'transparent').map((color, index) => (
                <RemovableColorButton
                  key={color.label}
                  color={color}
                  onColorClick={() => {
                    setCurrentColor(color.style);
                    setModifyColorIndex(index);
                    setIsEditingColor('highlightColor');
                  }}
                  canRemove
                  onRemove={() => handleRemoveHighlightColor(color.label)}
                />
              ))}
            </div>
          </div>}
        </Col >
        {
          configuration.colorEditionPerm && !isAdmin ? null : (
            <Col span={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '1rem' }}>
                <SketchPicker color={currentColor} onChange={handleOnChange} />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <Button
                  style={{ marginBottom: actionButtonBottomMargin }}
                  onClick={() => {
                    setConfiguration({
                      colors: [
                        { label: `color${uuid()}`, style: currentColor },
                        ...configuration.colors,
                      ],
                    });
                    setSummaryConfiguration({
                      colors: [
                        { label: `color${uuid()}`, style: currentColor },
                        ...summaryConfiguration.colors,
                      ],
                    });
                  }}
                  disabled={isEditingColor === 'templateColor' || configuration.colors.find(c => c.style === currentColor)}
                >
                  Ajouter au template
                </Button>
              </div>
              <div>
                <Button
                  style={{ marginBottom: actionButtonBottomMargin }}
                  onClick={() => {
                    setConfiguration({
                      highlightColors: [
                        { label: `color${uuid()}`, style: currentColor },
                        ...configuration.highlightColors,
                      ],
                    });
                  }}
                  disabled={isEditingColor === 'highlightColor' || configuration.highlightColors.find(c => c.style === currentColor)}
                >
                  Ajouter aux Highlights
                </Button>
              </div>
              <div>
                <Button
                  style={{ marginBottom: actionButtonBottomMargin }}
                  onClick={handleModifyColor}
                  disabled={!isEditingColor ||
                    mergeConfigurationColors(configuration, tmp).find(c => c.style === currentColor)?.fromTmp ||
                    mergeConfigurationHighlightColors(configuration, tmp).find(c => c.style === currentColor)?.fromTmp
                  }
                >
                  Modifier la couleur
                </Button>
              </div>
            </Col>
          )
        }
      </Row >
    </>
  );
};

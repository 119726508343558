import {
  ItemTypes,
  MASKS_TYPE,
  minContainerHeight,
  uuid,
} from '../constants/constants';
import {
  masksDefaultHeight,
  pageHeight,
  pageWidth,
} from '../constants/gridConfig';
import {
  isASelectionBox,
  mutateRemoveBox,
  mutateSelectContainer,
} from './boxes';
import { mutateAddColumns, mutateDeleteColumns, newColumn } from './columns';
import { removeSectionTmpConfiguration } from './styles';

export const newContainer = ({
  height = 'auto',
  columnsIds = [],
  editableConfiguration,
  KeyPdfToImage = null,
}) => {
  return {
    id: uuid(),
    columnsIds,
    height,
    editable: editableConfiguration,
    ...(KeyPdfToImage && { KeyPdfToImage }),
  };
};

export const mutateAddContainers = ({
  draft,
  index = draft.containers.length,
  count = 1,
  columnsSizes = [1],
  drawMode = false,
  box,
  from = null,
  KeyPdfToImage = null,
  fromCopiedContainer = false,
  shouldSelect = true,
}) => {
  const boxes = !box
    ? [] // Si `box` est null ou undefined, retourner un tableau vide
    : Array.isArray(box)
    ? box // Si `box` est déjà un tableau, l'utiliser directement
    : box.type === ItemTypes.DRAW
    ? [] // Si `box.type` est `ItemTypes.DRAW`, retourner un tableau vide
    : [box]; // Sinon, envelopper `box` dans un tableau

  const containerHeight = computeContainerHeight({
    drawMode,
    landscape: draft.landscape,
    boxType: box?.type,
    from,
    header: draft.masks.find((mask) => mask?.id === MASKS_TYPE.HEADER.id),
    footer: draft.masks.find((mask) => mask?.id === MASKS_TYPE.FOOTER.id),
  });
  const containersToInsert = Array(count)
    .fill()
    .map(() =>
      newContainer({
        height: fromCopiedContainer?.container_height ?? containerHeight,
        editableConfiguration:
          draft.currentProposal?.id !== null
            ? true
            : draft.configuration.editableTemplate,
        KeyPdfToImage,
      })
    );
  containersToInsert.forEach((container, i) => {
    const columns = columnsSizes.map((size) =>
      newColumn({
        size,
        drawMode,
        maskId: fromCopiedContainer?.maskId,
      })
    );
    const columnsIds = columns.map((c) => c.id);

    container.columnsIds = columnsIds;
    if (index !== null) {
      draft.containers.splice(index + i + 1, 0, container);
    } else {
      draft.containers.unshift(container);
    }
    if (fromCopiedContainer) {
      if (drawMode) mutateAddColumns(draft, columns, boxes);
      else {
        columns.forEach((column, i) => {
          mutateAddColumns(draft, [column], [boxes[i]]);
        });
      }
    } else {
      mutateAddColumns(draft, columns, boxes);
    }
  });
  if (shouldSelect && containersToInsert[0]) {
    mutateDropInContainer({ draft, container: containersToInsert[0], box });
  }
};

export const mutateRemoveContainer = (draft, containerId) => {
  let columnsToDeleteIds = [];
  draft.containers = draft.containers.filter((c) => {
    if (c.id === containerId) {
      columnsToDeleteIds = c.columnsIds;
      return false;
    }
    return true;
  });
  mutateDeleteColumns(draft, columnsToDeleteIds);
};

export const mutateRemoveContainers = (draft, containerIds = []) => {
  let columnsToDeleteIds = [];
  draft.containers = draft.containers.filter((c) => {
    if (containerIds.includes(c.id)) {
      columnsToDeleteIds.push(c.columnsIds);
      return false;
    }
    return true;
  });
  mutateDeleteColumns(draft, columnsToDeleteIds.flat());
};

export const mutateRemmoveSectionContainers = (
  draft,
  fromBoxId,
  { sectionId, dateTimestamp }
) => {
  let columnsToDeleteIds = [];
  const findBoxeByColumnId = (columnId) =>
    draft.boxes.find((b) => b.columnId === columnId);
  let sectionTmpId = [];
  draft.containers = draft.containers.filter((c) => {
    const box = findBoxeByColumnId(c.columnsIds[0]);
    const shouldDelete =
      (fromBoxId && c.fromBoxId === fromBoxId) ||
      (sectionId &&
        dateTimestamp &&
        c.sectionId === sectionId &&
        c.dateTimestamp === dateTimestamp) ||
      (box?.id === fromBoxId && box?.type === ItemTypes.SECTION_LINK);
    if (shouldDelete) {
      columnsToDeleteIds.push(...c.columnsIds);
      if (
        box?.from_linked_section &&
        (box?.tableStyleId ||
          box?.textStyleId ||
          box?.checkboxStyleId ||
          box?.selectorStyleId)
      )
        sectionTmpId.push(
          box?.tableStyleId ||
            box?.textStyleId ||
            box?.checkboxStyleId ||
            box?.selectorStyleId
        );
      return false;
    }
    return true;
  });
  removeSectionTmpConfiguration({
    sectionTmpId: [...new Set(sectionTmpId)],
    draft,
  });

  mutateDeleteColumns(draft, columnsToDeleteIds);
  const boxesFormToDelete = draft.boxes.filter(
    (b) =>
      (fromBoxId && b.fromBoxId === fromBoxId) ||
      b.id === b.fromBoxId ||
      (sectionId &&
        dateTimestamp &&
        b.fromSectionId === sectionId &&
        b.dateTimestamp === dateTimestamp)
  );
  for (const box of boxesFormToDelete) {
    mutateRemoveBox(draft, box.id);
  }
};

export const mutateUpdateContainer = (draft, updatedContainer) => {
  draft.containers = draft.containers.map((c) => {
    if (c.id === updatedContainer.id) {
      return updatedContainer;
    }
    return c;
  });
};

export const computeContainerHeight = ({
  drawMode,
  landscape,
  boxType,
  from = null,
  header,
  footer,
}) => {
  let containerHeight = 'auto';
  if (drawMode) {
    if (from === 'PdfToImageSizeMaxDefault') {
      containerHeight = maxContainerHeight({ header, footer });
    } else if (landscape && (!boxType || !isASelectionBox(boxType))) {
      containerHeight = pageWidth;
    } else if (boxType === ItemTypes.SECTION_VARIABLE) {
      containerHeight = minContainerHeight + 32;
    } else {
      containerHeight = minContainerHeight;
    }
  }
  return containerHeight;
};

export const mutateDropInContainer = ({ draft, container, box }) => {
  const drawMode = draft.columns.some(
    (c) => container.columnsIds.includes(c.id) && c.drawMode
  );
  mutateSelectContainer(draft, container);
  if (box && !drawMode) {
    draft.selectedBoxId = box.id;
  }
};

export const maxContainerHeight = ({ header, footer }) => {
  return (
    pageHeight -
    (header?.height ?? masksDefaultHeight.header) -
    (footer?.height ?? masksDefaultHeight.footer)
  );
};
